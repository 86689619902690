import { CompaniesContext } from './context'
import { CompaniesControllerProps } from './types'
export const CompaniesController = (
  props: CompaniesControllerProps
): JSX.Element => {
  const state = {}
  return (
    <CompaniesContext.Provider value={state}>
      {props.children}
    </CompaniesContext.Provider>
  )
}
