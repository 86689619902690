import { Stack, Typography } from '@mui/material'
import { OrganizationOverview } from '@pages/organization-overview'
import React from 'react'

export const PanelResume = (): JSX.Element => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" color="secondary.main">
        Dashboard
      </Typography>
      <OrganizationOverview />
    </Stack>
  )
}
