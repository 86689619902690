import { Grid } from '@mui/material'
import { MenuSelector } from './components/MenuSelector'
import { Wrapper } from './styles'
import { HeaderLogo } from './components/Logo'

export const Header = (): JSX.Element => {
  return (
    <Wrapper>
      <Grid container sx={{ px: 3 }}>
        <Grid item xs={3}>
          <HeaderLogo />
        </Grid>
        <Grid item xs={9}>
          <MenuSelector />
        </Grid>
      </Grid>
    </Wrapper>
  )
}
