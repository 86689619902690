import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { theme } from '@theme/index'

export const StyledTabs = styled(Tabs)({
  minHeight: '40px',
  maxHeight: '40px',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.divider,
  // '& .MuiTabs-indicator': {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     backgroundColor: 'transparent',
  // },
  // '& .MuiTabs-indicatorSpan': {
  //     maxWidth: 40,
  //     width: '100%',
  //     backgroundColor: '#635ee7',
  // },
})

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(14),
  marginRight: theme.spacing(0),
  color: theme.palette.primary.main,
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  height: '40px',
  minHeight: '40px',
  maxHeight: '40px',
  lineHeight: 0,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
  '&.Mui-focusVisible': {
    backgroundColor: `${theme.palette.primary.main}50`,
  },
}))
