import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { formatDate } from '@utils/formatter'
import { differenceInDays, parseISO } from 'date-fns'

type Props = {
  expire_at: string
}

export const OrganizationDatabaseExpireLabel = (props: Props): JSX.Element => {
  const diff = differenceInDays(parseISO(props.expire_at), new Date())
  const label = diff > 0 ? 'em %d dias' : diff < 0 ? 'há %d dias' : 'hoje'
  const absDay = Math.abs(diff)

  return (
    <Stack direction="column" alignItems="start">
      <Typography variant="body2" color="muted.main" lineHeight={1}>
        {formatDate(props.expire_at)}
      </Typography>
      <Typography variant="caption" color="muted.main" lineHeight={1}>
        <i>{label.replace('%d', String(absDay))}</i>
      </Typography>
    </Stack>
  )
}
