import { OrganizationDatabaseView } from './view'
import { OrganizationDatabaseProps } from './types'
import { OrganizationDatabaseController } from './controller'
export const OrganizationDatabase = (
  props: OrganizationDatabaseProps
): JSX.Element => {
  return (
    <OrganizationDatabaseController {...props}>
      <OrganizationDatabaseView />
    </OrganizationDatabaseController>
  )
}
