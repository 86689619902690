import { uid } from 'uid'
import { ReactElement } from 'react'
import { Box, Skeleton, SkeletonProps } from '@mui/material'

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const defaultProps = {
  words: 50,
}

export interface ShimmerTextProps extends SkeletonProps {
  isLoading: boolean
  words?: number
}

function ShimmerText({
  isLoading,
  children,
  height = 20,
  words = 50,
}: ShimmerTextProps) {
  if (!isLoading) return children as ReactElement

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
      {Array.from(Array(words)).map(() => (
        <Skeleton
          key={uid()}
          variant="rounded"
          animation="wave"
          width={randomIntFromInterval(14, 110)}
          height={height}
        />
      ))}
    </Box>
  )
}

ShimmerText.defaultProps = defaultProps

export { ShimmerText }
