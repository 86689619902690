import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { DatabaseDetailContext } from './context'
import { DatabaseDetailControllerProps } from './types'
import { DatabaseApi } from '@services/api/database_api'
import { DatabaseEntity } from '@entities/DatabaseEntity'
import { ModalFormComponent } from '@components/modal-form'
import { OrganizationUpload } from '@pages/organization-upload'
import { useWebsocket } from '@hooks/websocket'

export const DatabaseDetailController = (
  props: DatabaseDetailControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const ws = useWebsocket()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [database, setDatabase] = React.useState<DatabaseEntity | undefined>(
    undefined
  )

  const handleLoad = () => {
    setLoading(true)
    api
      .instanceOf<DatabaseApi>(DatabaseApi)
      .find(props.organizationId, 1, 1)
      .then((results) => setDatabase(results.results?.[0]))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const handleNewRecord = () => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Enviar base de dados">
        <OrganizationUpload
          organizationId={String(props.organizationId)}
          onSuccess={(database: DatabaseEntity) => {
            props.onUpload && props.onUpload(database)
            handleLoad()
          }}
        />
      </ModalFormComponent>
    )
  }

  React.useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateDatabase = (record: Record<string, unknown>) => {
    setDatabase(record as DatabaseEntity)
  }

  React.useEffect(() => {
    const unsubscribe = ws.subscribe(
      'EVENT_DATABASE_UPDATE',
      handleUpdateDatabase
    )
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const progress = React.useMemo(() => {
    if (!!loading) {
      return true
    }
    if (['PENDING', 'EXTRACTING'].includes(String(database?.status))) {
      return true
    }
    return false
  }, [loading, database])

  const currentDownloads = React.useMemo(() => {
    return (database?.sellers || []).filter((i) => i.downloaded === true).length
  }, [database])

  const totalDownloads = React.useMemo(() => {
    return (database?.sellers || []).length
  }, [database])

  const state = {
    loading,
    progress,
    database,
    currentDownloads,
    totalDownloads,
    handleLoad,
    handleNewRecord,
  }

  return (
    <DatabaseDetailContext.Provider value={state}>
      {props.children}
    </DatabaseDetailContext.Provider>
  )
}
