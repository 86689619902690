import './index.css'
import App from './App'
import React from 'react'
import '@fontsource/poppins'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { StorageProvider } from './hooks/storage'
import { ApiProvider } from './hooks/api'
import { DefaultTheme } from './theme'
import { ThemeProvider } from '@mui/material'
import { enableMapSet } from 'immer'
enableMapSet()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      <StorageProvider>
        <ApiProvider apiBaseURL="https://api.staging.avantor.com.br">
          <App />
        </ApiProvider>
      </StorageProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
