import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import { WrapperComponent } from '@containers/wrapper'
import footerPattern from '@assets/images/bg-pattern.svg'
import { ActiveAccountSelector } from './container/selector'

export const ActiveAccountView = (): JSX.Element => {
  return (
    <WrapperComponent>
      <Box
        sx={{
          minHeight: 'calc(100vh - 180px)',
          backgroundImage: [`url(${footerPattern})`].join(','),
          backgroundPosition: ['bottom center'].join(','),
          backgroundRepeat: 'repeat-x',
          backgroundSize: '60px',
        }}
      >
        <Container sx={{ py: 6 }}>
          <Stack justifyContent="center" alignItems="center">
            <ActiveAccountSelector />
          </Stack>
        </Container>
      </Box>
    </WrapperComponent>
  )
}
