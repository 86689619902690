import React from 'react'
import { theme } from '@theme/index'
import { LoadingButton } from '@mui/lab'
import { SignupContext } from '../context'
import { ShieldPlus } from '@phosphor-icons/react'
import { Stack, Alert, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { useForm } from 'react-hook-form'
import { SignupType } from '@type/signup_type'
import {
  FormInputCheck,
  FormInputPhone,
  FormInputText,
} from '@andreciornavei/mui-boost'

export const SignupFormContainer = (): JSX.Element => {
  const [accepted, setAccepted] = React.useState<boolean>(false)
  const error = useContextSelector(SignupContext, (s) => s.error)
  const loading = useContextSelector(SignupContext, (s) => s.loading)
  const handleSubmitForm = useContextSelector(
    SignupContext,
    (s) => s.handleSubmit
  )

  const { handleSubmit, control } = useForm<Partial<SignupType>>({
    defaultValues: {
      email: '',
      name: '',
      password: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={2}>
        {error?.message && <Alert severity="error">{error.message}</Alert>}
        <FormInputText
          name="name"
          control={control}
          label="Como você se chama?"
          disabled={loading}
          error={error?.errors?.name}
        />
        <FormInputText
          name="email"
          control={control}
          label="Seu email de trabalho"
          disabled={loading}
          error={error?.errors?.email}
        />
        <FormInputText
          name="password"
          type="password"
          control={control}
          disabled={loading}
          label="Crie sua senha de acesso"
          error={error?.errors?.password}
          helper={'Deve possuir ao menos 16 caracteres.'}
        />

        <Stack direction="column" spacing={2} py={1}>
          <Alert
            color="warning"
            icon={
              <ShieldPlus
                color={theme.palette.success.main}
                weight="duotone"
                size={21}
              />
            }
            sx={{
              borderWidth: 1,
              borderRadius: 0,
              borderStyle: 'solid',
              py: 0,
            }}
          >
            <Typography variant="caption">
              O seu telefone será utilizado para autenticação <b>2FA</b>
            </Typography>
          </Alert>
          <FormInputPhone
            name="phone"
            control={control}
            label="Qual o seu número de telefone?"
            disabled={loading}
            error={error?.errors?.phone}
          />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <FormInputCheck
            name="accept-terms"
            disabled={loading}
            checked={accepted}
            onChange={setAccepted}
          />
          <Typography variant="caption" color="muted.main" lineHeight={1.1}>
            Ao criar minha conta estou de acordo com os Termos de uso do
            software e Política de privacidade
          </Typography>
        </Stack>

        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!accepted}
          loading={loading}
        >
          Criar minha conta
        </LoadingButton>
      </Stack>
    </form>
  )
}
