import React from 'react'
import { AdminOrganizationContext } from './context'
import { AdminOrganizationControllerProps } from './types'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { OrganizationEntity } from '@entities/OrganizationEntity'
import { useApi } from '@hooks/api'
import { OrganizationApi } from '@services/api/organization_api'

export const AdminOrganizationController = (
  props: AdminOrganizationControllerProps
): JSX.Element => {
  const api = useApi()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { id: organizationId } = useParams<{ id: string }>()
  const [tab, setTab] = React.useState<string>(
    searchParams.get('tab') || 'overview'
  )
  const [loading, setLoading] = React.useState<boolean>(true)
  const [organization, setOrganization] = React.useState<
    OrganizationEntity | undefined
  >(undefined)

  const handleChangeTab = (_: React.SyntheticEvent, newTab: string) => {
    setTab(newTab)
  }

  React.useEffect(() => {
    navigate({ search: `${createSearchParams({ tab })}` }, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  const handleLoadOrganization = () => {
    setLoading(true)
    api
      .instanceOf<OrganizationApi>(OrganizationApi)
      .details(String(organizationId))
      .then(setOrganization)
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    if (organization) return
    handleLoadOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const state = {
    tab,
    loading,
    organization,
    organizationId,
    setOrganization,
    handleChangeTab,
  }

  return (
    <AdminOrganizationContext.Provider value={state}>
      {props.children}
    </AdminOrganizationContext.Provider>
  )
}
