import { useContextSelector } from 'use-context-selector'
import { AcceptInviteContext } from '../context'
import { AcceptInviteError } from './error'
import LoadingTag from '@components/loading-tag/LoadingTag'

export const AcceptInviteSelector = (): JSX.Element => {
  const loading = useContextSelector(AcceptInviteContext, (s) => s.loading)
  const error = useContextSelector(AcceptInviteContext, (s) => s.error)
  if (loading) return <LoadingTag message="Verificando convite..." />
  if (error) return <AcceptInviteError error={error} />
  return <div>abrindo aplicativo...</div>
}
