import React from 'react'
import { uid } from 'uid'
import { theme } from '@theme/index'
import { ptBR } from 'date-fns/locale'
import { format, parse } from 'date-fns'
import { useForm } from 'react-hook-form'
import { Shimmer } from '@components/shimmer'
import { formatCurrency } from '@utils/formatter'
import { Box, Popover, Stack, Typography } from '@mui/material'
import { FormInputPickPeriod } from '@andreciornavei/mui-boost'

export type ChartHistoryType = {
  title: string
  history_kind: 'D' | 'M'
  history_period: Record<string, number> // yyyy-MM-yy | 10
}

type ChartDataItem = {
  percentFill: number
  value: string
  date: Date
}

type ChartColumnProps = { data: ChartDataItem; monthViewMode: boolean }

const ChartColumn = (props: ChartColumnProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const loading = false

  const handlePopoverOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (loading) return
      setAnchorEl(event.currentTarget)
    },
    [loading]
  )

  const handlePopoverClose = React.useCallback(() => {
    if (loading) return
    setAnchorEl(null)
  }, [loading])

  const open = Boolean(anchorEl)
  return (
    <>
      <Stack direction="column" spacing={1} flexGrow={1}>
        <Box
          width="100%"
          height={100}
          display="flex"
          alignItems="flex-end"
          borderRadius={1}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            cursor: loading ? 'unset' : 'pointer',
            backgroundColor: theme.palette.muted.light,
            [`&:hover > *`]: {
              backgroundColor: loading ? undefined : theme.palette.primary.dark,
            },
          }}
        >
          <Shimmer isLoading={loading} width="100%" height="30%">
            <Box
              borderRadius={1}
              width="100%"
              height={props.data.percentFill}
              sx={{ backgroundColor: `${theme.palette.primary.dark}99` }}
            />
          </Shimmer>
        </Box>
        <Stack direction="column" alignItems="center">
          {!props.monthViewMode && (
            <Typography
              variant="caption"
              color="muted.main"
              fontWeight="bold"
              lineHeight={1}
            >
              {format(props.data.date, 'dd', { locale: ptBR })}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="muted.main"
            fontWeight="bold"
            fontSize={10}
            lineHeight={1}
          >
            {format(props.data.date, 'MMM', { locale: ptBR })}
          </Typography>
          {!props.monthViewMode && (
            <Typography
              variant="caption"
              color="muted.main"
              fontSize={10}
              lineHeight={1}
              mt={0.5}
              pt={0.1}
              borderColor={theme.palette.divider}
              sx={{ borderTopWidth: 1, borderTopStyle: 'solid' }}
            >
              {format(props.data.date, 'eeeeee', { locale: ptBR })}
            </Typography>
          )}
          {!!props.monthViewMode && (
            <Typography
              variant="caption"
              color="muted.main"
              fontSize={10}
              lineHeight={1}
            >
              {format(props.data.date, 'yyyy', { locale: ptBR })}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          marginTop: -0.5,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box px={0.5} sx={{ backgroundColor: theme.palette.secondary.main }}>
          <Typography variant="caption" color="primary.light" fontSize={11}>
            {props.data.value}
          </Typography>
        </Box>
      </Popover>
    </>
  )
}

export const ChartBar = (props: ChartHistoryType): JSX.Element => {
  const chartData = React.useMemo(() => {
    const data: ChartDataItem[] = []
    for (const i in props?.history_period || []) {
      const date = parse(i, 'yyyy-MM-dd', new Date())
      data.push({
        percentFill:
          (Number.parseInt(String(props?.history_period[i])) /
            Math.max(...Object.values(props?.history_period || {}))) *
          100,
        value: formatCurrency(props?.history_period[i] as number),
        date: date,
      })
    }
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const monthViewMode = React.useMemo(() => {
    return props?.history_kind === 'M'
  }, [props])

  const { handleSubmit, control } = useForm<{ period?: string }>({
    defaultValues: { period: 'D7' },
  })

  return (
    <form onSubmit={handleSubmit((data) => {})}>
      <Stack direction="column" spacing={1} justifyContent="space-between">
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="caption" color="muted.main" lineHeight={1}>
            {props.title}
          </Typography>
          <FormInputPickPeriod size="tiny" name="period" control={control} />
        </Stack>
        <Stack direction="row" spacing={1}>
          {chartData.map((data) => (
            <ChartColumn
              key={uid()}
              data={data}
              monthViewMode={monthViewMode}
            />
          ))}
        </Stack>
      </Stack>
    </form>
  )
}
