import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { ResetPasswordContext } from '../context'
import { Stack, Alert, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { ResetPasswordFormType } from '@type/reset_password_form_type'
import { FormInputText } from '@andreciornavei/mui-boost'

export const ResetPasswordFormContainer = (): JSX.Element => {
  const error = useContextSelector(ResetPasswordContext, (s) => s.error)
  const loading = useContextSelector(ResetPasswordContext, (s) => s.loading)
  const handleSubmitForm = useContextSelector(
    ResetPasswordContext,
    (s) => s.handleSubmit
  )

  const { handleSubmit, control } = useForm<Partial<ResetPasswordFormType>>({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={1}>
        <Stack direction="column" spacing={1}>
          <Typography variant="h1" color="secondary.main">
            Nova senha
          </Typography>
          <Typography variant="subtitle1" color="muted.main">
            Crie uma nova senha e volte a ter acesso ao avantor.
          </Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          {error?.message && <Alert severity="error">{error.message}</Alert>}
          <FormInputText
            control={control}
            name="password"
            type="password"
            disabled={loading}
            label="Senha super secreta"
            error={error?.errors?.password}
            helper={
              'Deve possuir ao menos 8 caracteres, 1 letra, 1 número e 1 caractere especial'
            }
          />
          <FormInputText
            control={control}
            name="password_confirmation"
            type="password"
            disabled={loading}
            label="Confirme sua senha super secreta"
            error={error?.errors?.password_confirmation}
          />
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Redefinir minha senha
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
