import { theme } from '@theme/index'
import { LoadingButton } from '@mui/lab'
import { useContextSelector } from 'use-context-selector'
import { AdminOrganizationMemberContext } from './context'
import { Alert, DialogActions, Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { OrganizationMemberFormType } from './types'
import { FormInputCheckbox, FormInputText } from '@andreciornavei/mui-boost'

export const AdminOrganizationMemberView = () => {
  const member = useContextSelector(
    AdminOrganizationMemberContext,
    (s) => s.member
  )

  const handleSubmitForm = useContextSelector(
    AdminOrganizationMemberContext,
    (s) => s.handleSubmit
  )

  const error = useContextSelector(
    AdminOrganizationMemberContext,
    (s) => s.error
  )

  const submiting = useContextSelector(
    AdminOrganizationMemberContext,
    (s) => s.submiting
  )

  const { handleSubmit, control } = useForm<OrganizationMemberFormType>({
    defaultValues: { email: member?.email, role: member?.role },
  })

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={3} px={3}>
        {error?.message && <Alert color="error">{error?.message}</Alert>}
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Typography variant="subtitle2" fontWeight="bold" color="secondary">
              Email de membro
            </Typography>
            <Typography variant="caption" color="muted.main" lineHeight={1.2}>
              Um email será enviado para o membro notificando o convite para
              participar da organização. Após o envio do email, o usuário pode
              escolher aceitar ou rejeitar o convite.
            </Typography>
          </Stack>
          <FormInputText
            name="email"
            label="E-mail"
            control={control}
            disabled={submiting || !!member}
            error={error?.errors?.email}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Stack direction="row" spacing={1} alignItems="baseline">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                color={error?.errors?.role ? 'error' : 'secondary'}
              >
                Permissão de acesso
              </Typography>
              {error?.errors?.role && (
                <Typography
                  variant="caption"
                  lineHeight={1.1}
                  color="error"
                  fontSize={10}
                >
                  {error?.errors?.role}
                </Typography>
              )}
            </Stack>
            <Typography variant="caption" color="muted.main" lineHeight={1.2}>
              Você pode conceder níveis de acesso diferentes aos membros da
              organização, garantindo que os usuários executem apenas os papéis
              pelos quais foram designados dentro do sistema.
            </Typography>
          </Stack>
          <FormInputCheckbox
            name="role"
            mode="single"
            control={control}
            options={[
              {
                enabled: !submiting,
                value: 'VIEWER',
                title: 'Visualização',
                description: (
                  <Typography
                    variant="caption"
                    color="muted.main"
                    lineHeight={1.2}
                  >
                    Permite apenas visualizar os dados da organização como (
                    <b>Resumo</b>, <b>Pedidos</b>, <b>Membros</b>,{' '}
                    <b>Vendedores</b> e <b>Bases de dados</b>)
                  </Typography>
                ),
              },
              {
                enabled: !submiting,
                value: 'EDITOR',
                title: 'Edição',
                subtitle: '(Herda as permissões do perfil de Visualização)',
                description: (
                  <Typography
                    variant="caption"
                    color="muted.main"
                    lineHeight={1.2}
                  >
                    Inclui permissões para usuários com papéis recorrentes no
                    sistema como (<b>Enviar base de dados</b> e{' '}
                    <b>Receber pedidos</b>)
                  </Typography>
                ),
              },
              {
                enabled: !submiting,
                value: 'ADMIN',
                title: 'Gestão',
                subtitle: '(Herda permissões do perfil de Edição)',
                description: (
                  <Typography
                    variant="caption"
                    color="muted.main"
                    lineHeight={1.2}
                  >
                    Concede acesso total da organização ao usuário para tarefas
                    restritas como (<b>Convidar membros</b>,{' '}
                    <b>Gerenciar vendedores</b> e <b>Editar organização</b>)
                  </Typography>
                ),
              },
            ]}
          />
        </Stack>
      </Stack>
      <DialogActions
        sx={{
          py: 3,
          px: 3,
          mt: 3,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <LoadingButton
          loading={submiting}
          type="submit"
          variant="contained"
          size="medium"
        >
          {member ? 'atualizar membro' : 'enviar convite'}
        </LoadingButton>
      </DialogActions>
    </form>
  )
}
