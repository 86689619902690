import _ from 'lodash'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import { RoleEnum } from '@enums/role_enum'
import { Dropdown } from '@components/dropdown'
import { InviteEnum } from '@enums/invite_enum'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { MemberEntity } from '@entities/MemberEntity'
import { useContextSelector } from 'use-context-selector'
import { formatDate, formatInitials } from '@utils/formatter'
import { PageListRowStandard } from '@components/page-list/styles'
import { DotsThree, PencilSimple, TrashSimple } from '@phosphor-icons/react'
import { OrganizationMembersContext } from '@pages/organization-members/context'
import { FormInputCheck } from '@andreciornavei/mui-boost'

type Props = {
  item: MemberEntity
}

export const OrganizationMembersTableRow = ({ item }: Props): JSX.Element => {
  const handleUpdateRecord = useContextSelector(
    OrganizationMembersContext,
    (s) => s.handleUpdateRecord
  )

  const handleRemoveMember = useContextSelector(
    OrganizationMembersContext,
    (s) => s.handleRemoveMember
  )

  return (
    <PageListRowStandard>
      <td align="center">
        <FormInputCheck name="check" />
      </td>
      <td align="left">
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            variant="rounded"
            sx={{ width: 32, height: 32, fontSize: 16 }}
            children={formatInitials(item?.user?.name || item.email)}
          />
          <Stack direction="column" py={2}>
            <Typography
              variant="caption"
              color="muted.main"
              fontWeight="bold"
              lineHeight={1}
            >
              {item?.user?.name || `<desconhecido>`}
            </Typography>
            <Typography variant="body2" color="muted.main" lineHeight={1}>
              {item.email}
            </Typography>
          </Stack>
        </Stack>
      </td>
      <td align="left">
        <Typography variant="body2" color="muted.main">
          {_.get(RoleEnum, item.role)}
        </Typography>
      </td>
      <td align="left">
        <Typography variant="body2" color="muted.main">
          {_.get(InviteEnum, item.status)}
        </Typography>
      </td>
      <td align="center">
        <Typography variant="body2" color="muted.main">
          {formatDate(item.created_at)}
        </Typography>
      </td>
      <td>
        <Dropdown
          options={[
            {
              label: 'Editar',
              icon: PencilSimple,
              action: () => handleUpdateRecord(item),
            },
            {
              label: 'Excluir',
              icon: TrashSimple,
              action: () => handleRemoveMember(item.id),
            },
          ]}
        >
          <IconButton size="small">
            <DotsThree />
          </IconButton>
        </Dropdown>
      </td>
    </PageListRowStandard>
  )
}
