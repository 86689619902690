import { AdminOrganizationController } from './controller'
import { AdminOrganizationProps } from './types'
import { AdminOrganizationView } from './view'

export const AdminOrganization = (
  props: AdminOrganizationProps
): JSX.Element => {
  return (
    <AdminOrganizationController {...props}>
      <AdminOrganizationView />
    </AdminOrganizationController>
  )
}
