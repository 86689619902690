import { Stack } from '@mui/material'
import { SuitcaseSimple } from '@phosphor-icons/react'
import { OrganizationSellersContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { PageListComponent } from '@components/page-list'
import { CardContainer } from '@components/card-container'
import { OrganizationSellersFilters } from './containers/filters'
import { OrganizationSellersTableRow } from './containers/table-row'
import { OrganizationSellersTableHeader } from './containers/table-header'

export const OrganizationSellersView = (): JSX.Element => {
  const tableRef = useContextSelector(
    OrganizationSellersContext,
    (s) => s.tableRef
  )

  const handleLoadRecords = useContextSelector(
    OrganizationSellersContext,
    (s) => s.handleLoadRecords
  )

  const handleNewRecord = useContextSelector(
    OrganizationSellersContext,
    (s) => s.handleNewRecord
  )

  return (
    <Stack direction="column" spacing={2}>
      <OrganizationSellersFilters />
      <CardContainer>
        <PageListComponent
          ref={tableRef}
          title="Lista de representantes"
          titleVariant="caption"
          titleColor="muted.main"
          icon={SuitcaseSimple}
          rowSpacing={5}
          emptyMessage="Nenhum representante comercial cadastrado"
          handlePagination={handleLoadRecords}
          handleNewRecord={handleNewRecord}
          renderHeader={() => <OrganizationSellersTableHeader />}
          renderItem={(item, _pre) => (
            <OrganizationSellersTableRow key={`row-${item.id}`} item={item} />
          )}
        />
      </CardContainer>
    </Stack>
  )
}
