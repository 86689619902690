import React from 'react'
import { Box, Grid } from '@mui/material'
import { SellerRoleEnum } from '@enums/seller_role_enum'
import { useContextSelector } from 'use-context-selector'
import { CardContainer } from '@components/card-container'
import { CalendarBlank, MagnifyingGlass } from '@phosphor-icons/react'
import { OrganizationSellersContext } from '@pages/organization-sellers/context'
import { useForm } from 'react-hook-form'
import { FormInputDatePicker, FormInputText } from '@andreciornavei/mui-boost'

export const OrganizationSellersFilters = React.memo((): JSX.Element => {
  const formRef = useContextSelector(
    OrganizationSellersContext,
    (s) => s.formRef
  )

  const tableRef = useContextSelector(
    OrganizationSellersContext,
    (s) => s.tableRef
  )

  const { handleSubmit, control } = useForm<{
    q?: string
    role?: string
    created_at?: string
  }>({
    defaultValues: {
      q: '',
      role: '',
      created_at: '',
    },
  })

  return (
    <CardContainer title="Filtrar resultados" icon={MagnifyingGlass}>
      <form
        // ref={formRef}
        onSubmit={handleSubmit((data) => tableRef.current.refresh(data))}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInputText
                theme="light"
                name="q"
                control={control}
                label="Pesquisar nome, email ou cpf"
                endIcon={<MagnifyingGlass />}
                onChangeDebounce={() => formRef?.current?.submitForm()}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormInputText
                    theme="light"
                    name="role"
                    label="Perfil"
                    options={{
                      '': '-- Todos --',
                      ...SellerRoleEnum,
                    }}
                    control={control}
                    onChangeDebounce={() => formRef?.current?.submitForm()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInputDatePicker
                    theme="light"
                    name="created_at"
                    label="Data de criação"
                    endIcon={<CalendarBlank />}
                    control={control}
                    // onChange={() => formRef?.current?.submitForm()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </CardContainer>
  )
})
