import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { useNavigate } from 'react-router-dom'
import { OrganizationsContext } from './context'
import { OrganizationsControllerProps } from './types'
import { ModalFormComponent } from '@components/modal-form'
import { OrganizationApi } from '@services/api/organization_api'
import { OrganizationCreation } from '../organization-creation'

export const OrganizationsController = (
  props: OrganizationsControllerProps
) => {
  const navigate = useNavigate()
  const modal = useModal()
  const api = useApi()

  const handleOpenOrganization = (organizationId: string) => {
    navigate(`/admin/dashboard/organizations/${organizationId}`)
  }

  const handleNewRecord = () => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Editar organização">
        <OrganizationCreation />
      </ModalFormComponent>
    )
  }

  const handlePagination = (page: number, limit: number) => {
    return api.instanceOf<OrganizationApi>(OrganizationApi).find(page, limit)
  }

  const state = {
    handleNewRecord,
    handlePagination,
    handleOpenOrganization,
  }

  return (
    <OrganizationsContext.Provider value={state}>
      {props.children}
    </OrganizationsContext.Provider>
  )
}
