import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { SignupContext } from './context'
import { SignupType } from '@type/signup_type'
import { useNavigate } from 'react-router-dom'
import { SignupControllerProps } from './types'
import { AuthApi } from '@services/api/auth_api'
import { HttpMessageType } from '@type/http_message_type'
import { Button, Container, Stack, Typography } from '@mui/material'

export const SignupController = (props: SignupControllerProps): JSX.Element => {
  const api = useApi()
  const { openModal, closeModal } = useModal()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<any | undefined>(undefined)
  const navigate = useNavigate()

  const navToLogin = () => {
    navigate('/')
  }

  const handleCloseSignupSuccess = () => {
    closeModal()
    navigate('/', { replace: true })
  }

  const handleSuccessSignup = (message: HttpMessageType) => {
    openModal(
      <Container sx={{ maxWidth: 640, width: 640 }}>
        <Stack spacing={2} py={4}>
          <Typography variant="h3">{message.title}</Typography>
          <Typography
            variant="body2"
            color="muted.dark"
            dangerouslySetInnerHTML={{ __html: message.message }}
            style={{ display: 'flex', gap: 12, flexDirection: 'column' }}
          />
        </Stack>
        <Stack direction="row" spacing={2} pb={3} justifyContent="flex-end">
          <Button onClick={handleCloseSignupSuccess} variant="text">
            Retornar a página de login
          </Button>
        </Stack>
      </Container>,
      { locked: true }
    )
  }

  const handleSubmit = (formData: Partial<SignupType>) => {
    setError(undefined)
    setLoading(true)
    api
      .instanceOf<AuthApi>(AuthApi)
      .signup(formData)
      .then(handleSuccessSignup)
      .catch((error) => setError(error.response.data))
      .finally(() => setLoading(false))
  }

  const state = {
    error,
    loading,
    navToLogin,
    handleSubmit,
  }

  return (
    <SignupContext.Provider value={state}>
      {props.children}
    </SignupContext.Provider>
  )
}
