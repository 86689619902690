import { useApp } from '@hooks/app'
import { theme } from '@theme/index'
import { ProfileEnum } from '@enums/profile_enum'
import { formatInitials } from '@utils/formatter'
import { ProfileSwitcherContext } from './context'
import { CaretDoubleDown } from '@phosphor-icons/react'
import { useContextSelector } from 'use-context-selector'
import { Avatar, Button, Menu, Stack, Typography } from '@mui/material'
import { ProfileSwitcherAdmin } from './containers/profile-switcher-admin'
import { ProfileSwitcherSellers } from './containers/profile-switcher-sellers'
import { ProfileSwitcherOrganizations } from './containers/profile-switcher-organizations'

export const ProfileSwitcherView = (): JSX.Element => {
  const { selectedProfile } = useApp()

  const open = useContextSelector(ProfileSwitcherContext, (s) => s.open)

  const anchorEl = useContextSelector(ProfileSwitcherContext, (s) => s.anchorEl)

  const handleClick = useContextSelector(
    ProfileSwitcherContext,
    (s) => s.handleClick
  )
  const handleClose = useContextSelector(
    ProfileSwitcherContext,
    (s) => s.handleClose
  )

  if (!selectedProfile) return <></>

  return (
    <>
      <Button
        variant="outlined"
        sx={{ borderColor: theme.palette.grey[300], pl: 1 }}
        startIcon={
          <Avatar
            variant="rounded"
            sx={{ width: 26, height: 26, margin: '3px', marginRight: 0 }}
          >
            <Typography variant="caption" lineHeight={0}>
              {formatInitials(selectedProfile?.label || 'unknown')}
            </Typography>
          </Avatar>
        }
        onClick={handleClick}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="column" alignItems="start">
            <Typography
              variant="caption"
              color="muted.main"
              fontSize={9}
              lineHeight={1.2}
              textTransform="uppercase"
            >
              {ProfileEnum[selectedProfile.kind]}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontWeight="bold"
              lineHeight={1}
            >
              {selectedProfile.label}
            </Typography>
          </Stack>
          <CaretDoubleDown size={12} />
        </Stack>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            '&:before': {
              left: '16px !important',
            },
          },
        }}
      >
        <ProfileSwitcherAdmin />
        <ProfileSwitcherOrganizations />
        <ProfileSwitcherSellers />
      </Menu>
    </>
  )
}
