import { OrganizationsView } from './view'
import { OrganizationsProps } from './types'
import { OrganizationsController } from './controller'

export const Organizations = (props: OrganizationsProps): JSX.Element => {
  return (
    <OrganizationsController {...props}>
      <OrganizationsView />
    </OrganizationsController>
  )
}
