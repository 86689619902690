import React from 'react'
import { theme } from '../../theme'
import { Link } from 'react-router-dom'
import { IconProps } from '@phosphor-icons/react'
import { ListItem, Stack, Typography } from '@mui/material'

type Props = {
  href: string
  label: string
  disabled?: boolean
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
}

export const SideMenuItem = (props: Props): JSX.Element => {
  return (
    <ListItem disabled={props.disabled}>
      <Link
        to={props.disabled ? '#' : props.href}
        style={{ width: '100%', textDecoration: 'none' }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <props.icon
            color={theme.palette.primary.main}
            size={21}
            weight="duotone"
          />
          <Typography variant="body2" color="primary">
            {props.label}
          </Typography>
        </Stack>
      </Link>
    </ListItem>
  )
}
