import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { useParams } from 'react-router-dom'
import { useWebsocket } from '@hooks/websocket'
import { OrganizationDatabaseContext } from './context'
import { PageListRefType } from '@components/page-list'
import { DatabaseApi } from '@services/api/database_api'
import { OrganizationDatabaseControllerProps } from './types'
import { ModalConfirmation } from '@components/modal-confirmation'

export const OrganizationDatabaseController = (
  props: OrganizationDatabaseControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const ws = useWebsocket()
  const tableRef = React.useRef<PageListRefType | undefined>(undefined)
  const { id: organizationId } = useParams<{ id: string }>()

  const handleLoadRecords = (page: number, limit: number) => {
    return api
      .instanceOf<DatabaseApi>(DatabaseApi)
      .find(String(organizationId), page, limit)
  }

  const handleRefresh = () => {
    tableRef.current?.refresh()
  }

  const handleCancelUpload = (databaseId: string) => {
    modal.openModal(
      <ModalConfirmation
        title="Cancelar processo"
        message="Ao cancelar o processo, as bases dos representantes serão peridas e um novo upload deverá ser realizado"
        onConfirm={() =>
          api.instanceOf<DatabaseApi>(DatabaseApi).cancel(databaseId)
        }
        onSuccess={handleRefresh}
      />
    )
  }

  const handleUpdateRecordStatus = (record: Record<string, unknown>) => {
    tableRef.current?.updateRecord(String(record.id), record)
  }

  React.useEffect(() => {
    const unsubscribe = ws.subscribe(
      'EVENT_DATABASE_UPDATE',
      handleUpdateRecordStatus
    )
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const state = {
    tableRef,
    organizationId: String(organizationId),
    handleRefresh,
    handleLoadRecords,
    handleCancelUpload,
  }
  return (
    <OrganizationDatabaseContext.Provider value={state}>
      {props.children}
    </OrganizationDatabaseContext.Provider>
  )
}
