import { Stack, Typography } from '@mui/material'
import { OrganizationMembers } from '@pages/organization-members'
import { useParams } from 'react-router-dom'

export const AdminOrganizationMembers = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" color="secondary.main">
        Membros
      </Typography>
      <OrganizationMembers organizationId={String(id)} />
    </Stack>
  )
}
