import { PaginationType } from '@type/pagination_type'
import { OrganizationEntity } from '../../entities/OrganizationEntity'
import { ApiAction } from '../../hooks/api/action'
import { OrganizationCreationFormType } from '@pages/profiles/admin/organization-creation/types'

export class OrganizationApi extends ApiAction {
  async details(organizationId: string): Promise<OrganizationEntity> {
    const response = await this.http.get<OrganizationEntity>(
      `/organizations/${organizationId}`
    )
    return response.data
  }

  async find(
    page: number,
    limit: number
  ): Promise<PaginationType<OrganizationEntity>> {
    const response = await this.http.get<PaginationType<OrganizationEntity>>(
      `/organizations?page=${page || 1}&limit=${limit || 10}`
    )
    return response.data
  }

  async update(
    organizationId: string,
    data: Partial<OrganizationEntity>
  ): Promise<OrganizationEntity> {
    const response = await this.http.put<OrganizationEntity>(
      `/organizations/${organizationId}`,
      data
    )
    return response.data
  }

  async delete(organizationId: string): Promise<OrganizationEntity> {
    const response = await this.http.delete<OrganizationEntity>(
      `/organizations/${organizationId}`
    )
    return response.data
  }

  async create(
    data: OrganizationCreationFormType
  ): Promise<OrganizationEntity> {
    const response = await this.http.post<OrganizationEntity>(
      `/organizations`,
      data
    )
    return response.data
  }
}
