import Stack from '@mui/material/Stack'
import { Database } from '@phosphor-icons/react'
import { OrganizationDatabaseContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { PageListComponent } from '@components/page-list'
import { CardContainer } from '@components/card-container'
import { DatabaseDetail } from '@containers/database-detail'
import { OrganizationDatabaseTableRow } from './containers/table-row'
import { OrganizationDatabaseTableHeader } from './containers/table-header'

export const OrganizationDatabaseView = (): JSX.Element => {
  const organizationId = useContextSelector(
    OrganizationDatabaseContext,
    (s) => s.organizationId
  )

  const tableRef = useContextSelector(
    OrganizationDatabaseContext,
    (s) => s.tableRef
  )

  const handleLoadRecords = useContextSelector(
    OrganizationDatabaseContext,
    (s) => s.handleLoadRecords
  )

  const handleRefresh = useContextSelector(
    OrganizationDatabaseContext,
    (s) => s.handleRefresh
  )

  return (
    <Stack direction="column" spacing={2}>
      <DatabaseDetail
        organizationId={organizationId}
        onUpload={handleRefresh}
      />
      <CardContainer>
        <PageListComponent
          ref={tableRef}
          title="Base de dados transmitidas"
          titleVariant="caption"
          titleColor="muted.main"
          icon={Database}
          rowSpacing={5}
          emptyMessage="Nenhum representante comercial cadastrado"
          handlePagination={handleLoadRecords}
          renderHeader={() => <OrganizationDatabaseTableHeader />}
          renderItem={(item) => (
            <OrganizationDatabaseTableRow key={`row-${item.id}`} item={item} />
          )}
        />
      </CardContainer>
    </Stack>
  )
}
