import { Box, Grid, Stack, Typography } from '@mui/material'
import { Shimmer } from '@components/shimmer'
import { IconProps } from '@phosphor-icons/react'
import { theme } from '@theme/index'
import { uid } from 'uid'

type Props = {
  title: string
  total: string
  summary: Record<string, number>
  loading?: boolean
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
}
export const CardContentCounter = (props: Props): JSX.Element => {
  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <props.icon color={theme.palette.muted.main} />
        <Typography variant="caption" color="muted.main" lineHeight={1}>
          {props.title}
        </Typography>
      </Stack>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Stack direction="column" spacing={1} width="100%">
              {Object.keys(props.summary).map((item) => (
                <Shimmer
                  key={uid()}
                  isLoading={props.loading || false}
                  height={24}
                  width={120}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Typography
                      color="muted.main"
                      variant="caption"
                      lineHeight={1}
                    >
                      {item}
                    </Typography>
                    <Typography
                      color="muted.dark"
                      variant="caption"
                      fontWeight="bold"
                      lineHeight={1}
                    >
                      {props.summary[item]}
                    </Typography>
                  </Stack>
                </Shimmer>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={4} alignItems="center" justifyContent="center">
            <Typography variant="h2" color="primary">
              {props.total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}
