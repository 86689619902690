import { Stack, Typography } from '@mui/material'
import { OrganizationDatabase } from '@pages/organization-database'

export const SelfOrganizationDatabase = (): JSX.Element => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" color="secondary.main">
        Base de Dados
      </Typography>
      <OrganizationDatabase />
    </Stack>
  )
}
