import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserEntity } from '@entities/UserEntity'
import { AppContextType, AppProviderProps } from './types'
import { MemberProfileType } from '@type/member_profile_type'
import { MemberEntity } from '@entities/MemberEntity'
import { useAuth } from '@hooks/auth'
import { useStorage } from '@hooks/storage'

const AppContext = React.createContext<AppContextType>({} as AppContextType)

export const AppProvider = (props: AppProviderProps): JSX.Element => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const auth = useAuth()
  const storage = useStorage()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [user, setUser] = React.useState<UserEntity | undefined>(undefined)
  const [selectedProfile, setSelectedProfile] = React.useState<
    undefined | MemberProfileType
  >(undefined)

  const handleSelectProfile = (profile: MemberProfileType | undefined) => {
    if (!profile) storage.remove('profile')
    else storage.set('profile', profile.key)
    setSelectedProfile(profile)
  }

  const acceptedMemberProfiles: MemberProfileType[] = React.useMemo(() => {
    const builder: MemberProfileType[] = []
    if (user?.admin) {
      builder.push({
        kind: 'admin',
        key: `admin::${user.id}`,
        value: {} as unknown as MemberEntity,
        label: user?.name || 'unknown',
      })
    }
    builder.push(
      ...(user?.member_organizations || []).reduce<MemberProfileType[]>(
        (members, member) => {
          if (member.status === 'ACCEPTED') {
            members.push({
              kind: 'organization',
              key: `organization::${member.organization_id}`,
              value: member,
              label: member.organization?.name || 'unknown',
            })
          }
          return members
        },
        []
      )
    )
    builder.push(
      ...(user?.member_sellers || []).reduce<MemberProfileType[]>(
        (members, member) => {
          if (member.status === 'ACCEPTED') {
            members.push({
              kind: 'seller',
              value: member,
              key: `seller::${member.organization_id}`,
              label: member.organization?.name || 'unknown',
            })
          }
          return members
        },
        []
      )
    )
    return builder
  }, [user])

  // auto select an organization if user is
  // member of someone
  React.useEffect(() => {
    // if profile is already selected, return
    if (!!selectedProfile) return
    // otherelse, select the first available profile
    if (acceptedMemberProfiles.length > 0) {
      // load savedProfile to avoit reset selection
      const localProfile = storage.get('profile')
      if (!!localProfile) {
        const index = acceptedMemberProfiles.findIndex(
          (p) => p.key === localProfile
        )
        handleSelectProfile(acceptedMemberProfiles[index >= 0 ? index : 0])
      } else {
        handleSelectProfile(acceptedMemberProfiles[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile, acceptedMemberProfiles])

  // navigate to dashboard everytime
  // a new organization is selected
  // - if not select one, then
  // + go to onboarding page
  React.useEffect(() => {
    if (auth.isAuthenticated) {
      // prevent redirections if profile
      // is already on its basepath or
      // redirect it if not.
      if (selectedProfile?.kind === 'admin') {
        const basepath = '/admin'
        if (!pathname.startsWith(basepath))
          navigate(`${basepath}/dashboard`, { replace: true })
      } else if (selectedProfile?.kind === 'organization') {
        const basepath = `/org/${selectedProfile.value.organization_id}`
        if (!pathname.startsWith(basepath))
          navigate(`${basepath}/dashboard`, { replace: true })
      } else {
        navigate(`/onboarding`, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile])

  const state = {
    user,
    loading,
    selectedProfile,
    acceptedMemberProfiles,
    setLoading,
    setUser,
    handleSelectProfile,
  }

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  )
}

export function useApp() {
  const context = React.useContext(AppContext)
  return context
}
