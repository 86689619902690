import { AdminOrganizationMemberController } from './controller'
import { AdminOrganizationMemberProps } from './types'
import { AdminOrganizationMemberView } from './view'

export const AdminOrganizationMember = (
  props: AdminOrganizationMemberProps
) => {
  return (
    <AdminOrganizationMemberController {...props}>
      <AdminOrganizationMemberView />
    </AdminOrganizationMemberController>
  )
}
