import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

export const HeaderMenuDefault = (): JSX.Element => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button href="/beneficits">Por que o avantor?</Button>
      <Button href="/pricing">Preços</Button>
      <Button href="/resources">Recursos</Button>
      <Button href="/docs">Documentação</Button>
      <Button href="/login" variant="outlined" color="secondary">
        Login
      </Button>
    </Stack>
  )
}
