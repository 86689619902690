import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { RoleEnum } from '@enums/role_enum'
import { InviteEnum } from '@enums/invite_enum'
import { useContextSelector } from 'use-context-selector'
import { CardContainer } from '@components/card-container'
import { CalendarBlank, MagnifyingGlass } from '@phosphor-icons/react'
import { OrganizationMembersContext } from '@pages/organization-members/context'
import { FormInputDatePicker, FormInputText } from '@andreciornavei/mui-boost'
import { OrganizationMembersFilterFormType } from '@pages/organization-members/types'

export const OrganizationMembersFilters = (): JSX.Element => {
  const formRef = useContextSelector(
    OrganizationMembersContext,
    (s) => s.formRef
  )

  const tableRef = useContextSelector(
    OrganizationMembersContext,
    (s) => s.tableRef
  )

  const { handleSubmit, control } = useForm<OrganizationMembersFilterFormType>({
    defaultValues: { q: '', role: '', status: '', created_at: '' },
  })

  return (
    <CardContainer title="Filtrar resultados" icon={MagnifyingGlass}>
      <form
        // ref={formRef}
        onSubmit={handleSubmit((data) => tableRef.current.refresh(data))}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormInputText
                theme="light"
                name="q"
                label="Pesquisar nome"
                control={control}
                endIcon={<MagnifyingGlass />}
                onChangeDebounce={() => formRef?.current?.submitForm()}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormInputText
                    theme="light"
                    name="role"
                    label="Perfil"
                    options={{
                      '': '-- Todos --',
                      ...RoleEnum,
                    }}
                    control={control}
                    onChangeDebounce={() => formRef?.current?.submitForm()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText
                    theme="light"
                    name="status"
                    label="Situação"
                    options={{
                      '': '-- Todos --',
                      ...InviteEnum,
                    }}
                    control={control}
                    onChangeDebounce={() => formRef?.current?.submitForm()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputDatePicker
                    theme="light"
                    name="created_at"
                    label="Data de criação"
                    endIcon={<CalendarBlank />}
                    // onChange={() => formRef?.current?.submitForm()}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </CardContainer>
  )
}
