import { WrapperComponent } from '@containers/wrapper'
import { AcceptInviteSelector } from './containers/selector'
import { WrapperInnerComponent } from '@containers/wrapper-inner'

export const AcceptInviteView = (): JSX.Element => {
  return (
    <WrapperComponent>
      <WrapperInnerComponent>
        <AcceptInviteSelector />
      </WrapperInnerComponent>
    </WrapperComponent>
  )
}
