import { OrganizationSettingsController } from './controller'
import { OrganizationSettingsProps } from './types'
import { OrganizationSettingsView } from './view'

export const OrganizationSettings = (
  props: OrganizationSettingsProps
): JSX.Element => {
  return (
    <OrganizationSettingsController {...props}>
      <OrganizationSettingsView />
    </OrganizationSettingsController>
  )
}
