import { ItemRow } from './styles'
import { theme } from '@theme/index'
import { UsersPageContext } from './context'
import { UserEntity } from '@entities/UserEntity'
import { PageListComponent } from '@components/page-list'
import { useContextSelector } from 'use-context-selector'
import { FormInputCheck } from '@andreciornavei/mui-boost'
import { Key, IdentificationCard } from '@phosphor-icons/react'
import { formatDateTime, formatInitials } from '@utils/formatter'
import { Avatar, Chip, IconButton, Stack, Typography } from '@mui/material'

export const UsersPageView = () => {
  const handlePagination = useContextSelector(
    UsersPageContext,
    (s) => s.handlePagination
  )

  return (
    <PageListComponent
      title="Usuários"
      emptyMessage="Vazio"
      handlePagination={handlePagination}
      renderHeader={() => (
        <tr>
          <th style={{ padding: '0px', width: 50 }} />
          <th style={{ padding: '0px 10px', width: 60 }} align="center">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              STATUS
            </Typography>
          </th>
          <th style={{ padding: '0px 10px', width: 50 }} align="center">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              PERFIL
            </Typography>
          </th>
          <th style={{ padding: '0px 10px' }} align="left">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              NOME / EMAIL
            </Typography>
          </th>
          <th style={{ padding: '0px 25px' }} align="right">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              INSCRITO EM
            </Typography>
          </th>
        </tr>
      )}
      renderItem={(item: UserEntity) => (
        <ItemRow key={`row-${item.id}`}>
          <td style={{ padding: 10, paddingRight: 0 }}>
            <FormInputCheck name="check" />
          </td>
          <td style={{ padding: 10 }} align="center">
            <Chip
              color={item.active ? 'success' : 'error'}
              variant="outlined"
              label={item.active ? 'Ativo' : 'Inativo'}
              size="small"
            />
          </td>
          <td align="center">
            <IconButton>
              {!item.admin ? (
                <Key
                  size={24}
                  weight="duotone"
                  color={theme.palette.success.main}
                />
              ) : (
                <IdentificationCard
                  size={24}
                  weight="duotone"
                  color={theme.palette.primary.main}
                />
              )}
            </IconButton>
          </td>
          <td style={{ padding: 10 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                variant="rounded"
                sx={{ width: 32, height: 32, fontSize: 16 }}
                children={formatInitials(item.name || 'unknown')}
              />
              <Stack direction="column" spacing={0}>
                <Typography variant="body2" color="secondary.main">
                  {item.name || 'unknown'}
                </Typography>
                <Typography variant="caption" color="muted.main">
                  {item.email || 'unknown'}
                </Typography>
              </Stack>
            </Stack>
          </td>
          <td style={{ padding: 10, paddingRight: 25 }} align="right">
            <Typography variant="body2" color="secondary.main">
              {formatDateTime(item.created_at)}
            </Typography>
          </td>
        </ItemRow>
      )}
    />
  )
}
