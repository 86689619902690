import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { isCep } from 'validator-brazil'
import { useViacep } from '@services/viacep'
import { ReviewType } from '@type/review_type'
import { OrganizationSettingsContext } from './context'
import { OrganizationSettingsControllerProps } from './types'
import { OrganizationApi } from '@services/api/organization_api'
import { OrganizationEntity } from '@entities/OrganizationEntity'
import { OrganizationDeleteForm } from './containers/delete-form'

export const OrganizationSettingsController = (
  props: OrganizationSettingsControllerProps
): JSX.Element => {
  const api = useApi()
  const { findCep } = useViacep()
  const { openModal } = useModal()
  const formRef = React.useRef<any | undefined>(undefined)
  const [success, setSuccess] = React.useState<boolean>(false)
  const [findingAddress, setFindingAddress] = React.useState<boolean>(false)
  const [submiting, setSubmiting] = React.useState<boolean>(false)
  const [error, setError] = React.useState<ReviewType | undefined>(undefined)

  const handleFindCep = (cep: string): void => {
    if (!isCep(cep)) return
    setFindingAddress(true)
    findCep(cep)
      .then((response: any) => {
        const address = JSON.parse(response.data)
        formRef?.current?.setFieldValue?.('address.state', address.uf)
        formRef?.current?.setFieldValue?.('address.city', address.localidade)
        formRef?.current?.setFieldValue?.('address.street', address.logradouro)
        formRef?.current?.setFieldValue?.(
          'address.neighborhood',
          address.bairro
        )
      })
      .catch((error) => console.log('CATCH ERROR'))
      .finally(() => setFindingAddress(false))
  }

  const handleSucceessOrganization = (organization: OrganizationEntity) => {
    setSuccess(true)
    props.setOrganization(organization)
  }

  const handleUpdateOrganization = (data: Partial<OrganizationEntity>) => {
    setError(undefined)
    setSuccess(false)
    setSubmiting(true)
    api
      .instanceOf<OrganizationApi>(OrganizationApi)
      .update(String(props.organization?.id), data)
      .then(handleSucceessOrganization)
      .catch((error) => setError(error.response.data))
      .finally(() => setSubmiting(false))
  }

  const handleDeleteOrganization = () => {
    return api
      .instanceOf<OrganizationApi>(OrganizationApi)
      .delete(String(props.organization?.id))
  }

  const handleConfirmDeleteOrganization = () => {
    openModal(
      <OrganizationDeleteForm
        organization={props.organization}
        handleDeleteOrganization={handleDeleteOrganization}
      />
    )
  }

  const state = {
    formRef,
    error,
    success,
    submiting,
    organization: props.organization,
    findingAddress,
    setSuccess,
    handleFindCep,
    handleUpdateOrganization,
    handleDeleteOrganization,
    handleConfirmDeleteOrganization,
  }

  return (
    <OrganizationSettingsContext.Provider value={state}>
      {props.children}
    </OrganizationSettingsContext.Provider>
  )
}
