import Typography from '@mui/material/Typography'

export const OrganizationDatabaseTableHeader = (): JSX.Element => {
  return (
    <tr>
      <th style={{ width: 60 }}>&nbsp;</th>
      <th align="left">
        <Typography variant="caption" color="muted.main" fontWeight="bold">
          Status
        </Typography>
      </th>
      <th align="left" style={{ paddingLeft: 25, paddingRight: 25 }}>
        <Typography variant="caption" color="muted.main" fontWeight="bold">
          Downloads
        </Typography>
      </th>
      <th align="left" style={{ minWidth: 100 }}>
        <Typography variant="caption" color="muted.main" fontWeight="bold">
          Expiração
        </Typography>
      </th>
      <th style={{ width: 60 }}>&nbsp;</th>
    </tr>
  )
}
