import React from "react"
import Router from "./router"
import { AuthProvider } from "./hooks/auth"
import { ModalProvider } from "./hooks/modal"
import { Modal } from "./components/modal"
import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter
} from 'react-router-dom'
import { AppProvider } from "./hooks/app"
export default function App() {
  return (
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={5000}
      anchorOrigin={{ "horizontal": "center", "vertical": "top" }}
    >
      <BrowserRouter>
        <AuthProvider>
          <AppProvider>
            <ModalProvider>
              <Router />
              <Modal />
            </ModalProvider>
          </AppProvider>
        </AuthProvider>
      </BrowserRouter>
    </SnackbarProvider>
  )
}
