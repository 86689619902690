import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { ReviewType } from '@type/review_type'
import { MembersApi } from '@services/api/member_api'
import { AdminOrganizationMemberContext } from './context'

import {
  AdminOrganizationMemberControllerProps,
  OrganizationMemberFormType,
} from './types'

export const AdminOrganizationMemberController = (
  props: AdminOrganizationMemberControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const [submiting, setSubmiting] = React.useState<boolean>(false)
  const [error, setError] = React.useState<ReviewType | undefined>(undefined)

  const handleSubmit = (data: OrganizationMemberFormType) => {
    setSubmiting(true)
    setError(undefined)

    const actions = {
      create: () =>
        api
          .instanceOf<MembersApi>(MembersApi)
          .create(props.organizationId, data),
      update: () =>
        api
          .instanceOf<MembersApi>(MembersApi)
          .update(props.organizationId, String(props?.member?.id), data),
    }

    actions[props.member ? 'update' : 'create']()
      .then((response) => {
        modal.closeModal()
        props.onSave(response)
      })
      .catch((error) => setError(error.response.data))
      .finally(() => setSubmiting(false))
  }

  const state = {
    error,
    submiting,
    handleSubmit,
    member: props.member,
  }
  return (
    <AdminOrganizationMemberContext.Provider value={state}>
      {props.children}
    </AdminOrganizationMemberContext.Provider>
  )
}
