import React from 'react'
import axios from 'axios'
import { ApiAction } from './action'
import { ApiProps, ApiStateProps } from './types'

const ApiContext = React.createContext<ApiStateProps>({} as ApiStateProps)

export const ApiProvider = (props: ApiProps): JSX.Element => {
  const [token, setToken] = React.useState<string | undefined>(undefined)

  const http = axios.create({
    baseURL: props.apiBaseURL,
    withCredentials: true,
  })

  const instanceOf = <T extends ApiAction>(action: typeof ApiAction): T => {
    return new action(http) as T
  }

  const state = {
    token,
    http,
    setToken,
    instanceOf,
  }

  return (
    <ApiContext.Provider value={state}>{props.children}</ApiContext.Provider>
  )
}

export function useApi() {
  const context = React.useContext(ApiContext)
  return context
}
