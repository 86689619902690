import React from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Typography } from '@mui/material'
import { FormInputText } from '@andreciornavei/mui-boost'
import { OrganizationEntity } from '@entities/OrganizationEntity'
import { ModalConfirmation } from '@components/modal-confirmation'

type Props = {
  organization: OrganizationEntity | undefined
  handleDeleteOrganization: () => Promise<OrganizationEntity>
}
export const OrganizationDeleteForm = ({
  organization,
  handleDeleteOrganization,
}: Props): JSX.Element => {
  const [confirmation, setConfirmation] = React.useState<string>('')

  const confirmed = React.useMemo(() => {
    return organization?.name === confirmation
  }, [organization, confirmation])

  const { handleSubmit, control } = useForm<{ confirmation?: string }>()

  return (
    <ModalConfirmation
      onSuccess={(data) => {}}
      onConfirm={handleDeleteOrganization}
      title="Você realmente deseja excluir essa organização?"
      maxWidth={600}
      cancelLabel="Cancelar, manter a organização"
      confirmLabel="Sim, excluir organização"
      confirmDisabled={!confirmed}
      message={
        <form onSubmit={handleSubmit(() => {})}>
          <Stack direction="column">
            <Typography variant="caption" color="muted.main">
              Escreva a razão social da organização para confirmar:
            </Typography>
            <Typography variant="caption" fontWeight="bold" color="muted.dark">
              {organization?.name}
            </Typography>
            <FormInputText
              label=""
              control={control}
              name="confirmation"
              onChangeDebounce={setConfirmation}
            />
          </Stack>
        </form>
      }
    />
  )
}
