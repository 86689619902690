import { OrganizationMembersController } from './controller'
import { OrganizationMembersView } from './view'

type AdminOrganizationMembersProps = {
  organizationId: string
}

export const OrganizationMembers = (
  props: AdminOrganizationMembersProps
): JSX.Element => {
  return (
    <OrganizationMembersController {...props}>
      <OrganizationMembersView />
    </OrganizationMembersController>
  )
}
