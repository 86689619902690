import { SideMenuItem } from '@containers/side-menu-item'
import { SideMenuPadding } from '@containers/side-menu/styles'
import {
  Cardholder,
  Gauge,
  Receipt,
  Suitcase,
  UsersFour,
} from '@phosphor-icons/react'

export const SideMenuProfileAdmin = (): JSX.Element => {
  const buildBasePath = (append?: string) =>
    [`/admin/dashboard`, append].filter((i) => !!i).join('/')

  return (
    <SideMenuPadding
      sx={{ p: { xs: '12px', md: '24px' }, pt: { xs: '12px', md: '12px' } }}
    >
      <SideMenuItem href={buildBasePath()} label="Dashboard" icon={Gauge} />
      <SideMenuItem
        href={buildBasePath('organizations')}
        label="Organizações"
        icon={Suitcase}
      />
      <SideMenuItem
        label="Usuários"
        icon={UsersFour}
        href={buildBasePath('users')}
      />
      <SideMenuItem
        disabled
        icon={Receipt}
        label="Assinaturas"
        href={buildBasePath('subscriptions')}
      />
      <SideMenuItem
        disabled
        label="Cobrança"
        icon={Cardholder}
        href={buildBasePath('billing')}
      />
    </SideMenuPadding>
  )
}
