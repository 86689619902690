import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { OrganizationUploadContext } from './context'
import { DatabaseApi } from '@services/api/database_api'
import { DatabaseEntity } from '@entities/DatabaseEntity'
import { OrganizationUploadControllerProps } from './types'
import { PresignedUrlType } from '@type/presigned_url_type'

export const OrganizationUploadController = (
  props: OrganizationUploadControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const [file, setFile] = React.useState<File | undefined>(undefined)
  const [loading, setLoading] = React.useState<'IDLE' | 'PRESIGN' | 'UPLOAD'>(
    'IDLE'
  )

  const handlePresignUrl = () => {
    if (!file) return
    setLoading('PRESIGN')
    api
      .instanceOf<DatabaseApi>(DatabaseApi)
      .presignUrl(props.organizationId)
      .then((database) => handleUpload(database, file))
      .catch((_) => setLoading('IDLE'))
  }

  const handleUpload = (database: DatabaseEntity, file: File) => {
    if (!file) return
    setLoading('UPLOAD')
    api
      .instanceOf<DatabaseApi>(DatabaseApi)
      .upload(database.presigned_url as PresignedUrlType, file)
      .then(() => handleSuccessStart(database))
      .catch((_) => console.log('UPLOAD ERROR'))
      .finally(() => setLoading('IDLE'))
  }

  const handleSuccessStart = (database: DatabaseEntity) => {
    modal.closeModal()
    if (props.onSuccess) props.onSuccess(database)
  }

  const state = {
    file,
    loading,
    setFile,
    handlePresignUrl,
  }
  return (
    <OrganizationUploadContext.Provider value={state}>
      {props.children}
    </OrganizationUploadContext.Provider>
  )
}
