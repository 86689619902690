import { WrapperComponent } from '@containers/wrapper'
import {
  Grid,
  Stack,
  Button,
  Container,
  Typography,
  Divider,
  List,
  ListItem,
  Box,
} from '@mui/material'

import { LoginFormContainer } from './containers/form'

import footerPattern from '@assets/images/bg-pattern.svg'
import { useContextSelector } from 'use-context-selector'
import { LoginContext } from './context'

export const LoginView = (): JSX.Element => {
  const navToForgotPassword = useContextSelector(
    LoginContext,
    (s) => s.navToForgotPassword
  )

  const navToSignup = useContextSelector(LoginContext, (s) => s.navToSignup)

  return (
    <WrapperComponent>
      <Box
        sx={{
          minHeight: 'calc(100vh - 180px)',
          backgroundImage: [`url(${footerPattern})`].join(','),
          backgroundPosition: ['bottom center'].join(','),
          backgroundRepeat: 'repeat-x',
          backgroundSize: '60px',
        }}
      >
        <Container sx={{ py: 12 }}>
          <Grid container spacing={2}>
            <Grid
              item
              sm={1}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            ></Grid>
            <Grid item xs={12} sm={5}>
              <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h1" color="secondary.main">
                    Olá, bem vindo
                  </Typography>
                  <Typography variant="subtitle1" color="muted.main">
                    Informe suas credenciais para acessar a plataforma.
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1} alignItems="start">
                  <Box width="100%">
                    <LoginFormContainer />
                  </Box>
                  <Button
                    size="small"
                    variant="text"
                    onClick={navToForgotPassword}
                  >
                    Esqueci minha senha
                  </Button>
                </Stack>
                <Divider textAlign="center">
                  <Typography variant="body2">ou</Typography>
                </Divider>
                <Button variant="text" size="small" onClick={navToSignup}>
                  Criar uma conta grátis
                </Button>
              </Stack>
            </Grid>
            <Grid
              item
              sm={1}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            ></Grid>
            <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Typography variant="h1" color="secondary.main">
                Benefícios
              </Typography>
              <List>
                <ListItem sx={{ paddingLeft: 0 }}>
                  <Stack direction="column">
                    <Typography variant="subtitle1" color="secondary.main">
                      Importação / Exportação
                    </Typography>
                    <Typography variant="body2" color="muted.main">
                      Sincronize a base de dados da sua empresa com o aparelho
                      dos seus representantes comerciais.
                    </Typography>
                  </Stack>
                </ListItem>
                <Divider />
                <ListItem sx={{ paddingLeft: 0 }}>
                  <Stack direction="column">
                    <Typography variant="subtitle1" color="secondary.main">
                      Controle de representantes
                    </Typography>
                    <Typography variant="body2" color="muted.main">
                      Cadastre seus representantes comerciais em apenas alguns
                      cliques e comece a receber pedidos.
                    </Typography>
                  </Stack>
                </ListItem>
                <Divider />
                <ListItem sx={{ paddingLeft: 0 }}>
                  <Stack direction="column">
                    <Typography variant="subtitle1" color="secondary.main">
                      Multiplataforma
                    </Typography>
                    <Typography variant="body2" color="muted.main">
                      Integre com o seu ERP online ou baixe o nosso agente que
                      disponibiliza os arquivos dos pedidos para a sua rede
                      local.
                    </Typography>
                  </Stack>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </WrapperComponent>
  )
}
