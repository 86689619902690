import { StyledTab, StyledTabs } from '@components/tabs/tabs.style'
import { useContextSelector } from 'use-context-selector'
import { AdminOrganizationContext } from '../context'

export const AdminOrganizationTabItems = (): JSX.Element => {
  const tab = useContextSelector(AdminOrganizationContext, (s) => s.tab)
  const loading = useContextSelector(AdminOrganizationContext, (s) => s.loading)

  const handleChangeTab = useContextSelector(
    AdminOrganizationContext,
    (s) => s.handleChangeTab
  )

  return (
    <StyledTabs
      value={tab}
      onChange={handleChangeTab}
      aria-label="styled tabs example"
    >
      <StyledTab label="Resumo" value="overview" disabled={loading} />
      <StyledTab label="Pedidos" value="orders" disabled />
      <StyledTab label="Membros" value="members" disabled={loading} />
      <StyledTab label="Vendedores" value="sellers" disabled={loading} />
      <StyledTab label="Base de dados" value="database" disabled={loading} />
      <StyledTab label="Configurações" value="settings" disabled={loading} />
    </StyledTabs>
  )
}
