import React from 'react'
import { useApp } from '@hooks/app'
import { MemberProfileType } from '@type/member_profile_type'
import { ProfileSwitcherItemsInterface } from '../components/profile-switcher-items-interface'

export const ProfileSwitcherSellers = (): JSX.Element => {
  const { user } = useApp()
  const members: MemberProfileType[] = React.useMemo(() => {
    return (user?.member_sellers || [])
      .filter((member) => member.status === 'ACCEPTED')
      .map((member) => ({
        kind: 'seller',
        key: `seller::${member.organization_id}`,
        label: member.organization?.name || 'unknown',
        value: member,
      }))
  }, [user])

  return <ProfileSwitcherItemsInterface profile="seller" members={members} />
}
