import { useContextSelector } from 'use-context-selector'
import { ForgotPasswordFeedback } from './feedback'
import { ForgotPasswordContext } from '../context'
import { ForgotPasswordFormContainer } from './form'

export const ForgotPasswordSelector = (): JSX.Element => {
  const submited = useContextSelector(ForgotPasswordContext, (s) => s.submited)
  if (submited) return <ForgotPasswordFeedback />
  return <ForgotPasswordFormContainer />
}
