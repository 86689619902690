import { SideMenuItem } from '@containers/side-menu-item'
import { SideMenuPadding } from '@containers/side-menu/styles'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Storefront } from '@phosphor-icons/react'
import {
  Car,
  Database,
  Faders,
  Gauge,
  Handbag,
  Pen,
  Suitcase,
  UsersFour,
} from '@phosphor-icons/react'
import { useParams } from 'react-router-dom'

export const SideMenuOrganization = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const buildBasePath = (append?: string) =>
    [`/org/${id}`, append].filter((i) => !!i).join('/')

  return (
    <SideMenuPadding
      sx={{ p: { xs: '12px', md: '24px' }, pt: { xs: '12px', md: '12px' } }}
    >
      <SideMenuItem
        href={buildBasePath('dashboard')}
        label="Dashboard"
        icon={Gauge}
      />

      <SideMenuItem
        href={buildBasePath('database')}
        label="Base de dados"
        icon={Database}
      />
      <SideMenuItem
        href={buildBasePath('sellers')}
        label="Representantes"
        icon={Suitcase}
      />
      <SideMenuItem
        href={buildBasePath('members')}
        label="Membors"
        icon={UsersFour}
      />
      <Divider textAlign="left" sx={{ my: 1 }}>
        <Typography variant="caption" color="muted.main">
          entrada de dados
        </Typography>
      </Divider>
      <SideMenuItem
        disabled
        icon={Handbag}
        label="Pedidos"
        href={buildBasePath('orders')}
      />
      <SideMenuItem
        disabled
        icon={Car}
        label="Visitas"
        href={buildBasePath('visits')}
      />
      <SideMenuItem
        disabled
        icon={Storefront}
        label="Clientes"
        href={buildBasePath('customers')}
      />
      <Divider textAlign="left" sx={{ my: 1 }}>
        <Typography variant="caption" color="muted.main">
          minha conta
        </Typography>
      </Divider>
      <SideMenuItem
        disabled
        icon={Pen}
        label="Assinatura"
        href={buildBasePath('subscription')}
      />
      <SideMenuItem
        disabled
        icon={Faders}
        label="Configurações"
        href={buildBasePath('settings')}
      />
    </SideMenuPadding>
  )
}
