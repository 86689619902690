import { useContextSelector } from 'use-context-selector'
import { AdminOrganizationContext } from '../context'
import { PageLoading } from '@components/page-loading'
import { OrganizationSellers } from '@pages/organization-sellers'
import { OrganizationOverview } from '@pages/organization-overview'
import { OrganizationDatabase } from '@pages/organization-database'
import { AdminOrganizationOrders } from '@pages/organization-orders'
import { OrganizationMembers } from '@pages/organization-members'
import { OrganizationSettings } from '@pages/organization-settings'

export const AdminOrganizationTabSelector = (): JSX.Element => {
  const loading = useContextSelector(AdminOrganizationContext, (s) => s.loading)
  const organization = useContextSelector(
    AdminOrganizationContext,
    (s) => s.organization
  )
  const setOrganization = useContextSelector(
    AdminOrganizationContext,
    (s) => s.setOrganization
  )
  const organizationId = useContextSelector(
    AdminOrganizationContext,
    (s) => s.organizationId
  )
  const tab = useContextSelector(AdminOrganizationContext, (s) => s.tab)
  if (loading) return <PageLoading label="carregando organização..." />
  if (tab === 'overview') return <OrganizationOverview />
  if (tab === 'orders')
    return <AdminOrganizationOrders organizationId={String(organizationId)} />
  if (tab === 'members')
    return <OrganizationMembers organizationId={String(organizationId)} />
  if (tab === 'settings')
    return (
      <OrganizationSettings
        organization={organization}
        setOrganization={setOrganization}
      />
    )
  if (tab === 'sellers') return <OrganizationSellers />
  if (tab === 'database') return <OrganizationDatabase />
  return <div>unknown</div>
}
