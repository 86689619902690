import _ from 'lodash'
import { theme } from '@theme/index'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import { Dropdown } from '@components/dropdown'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { SellerEntity } from '@entities/SellerEntity'
import { SellerRoleEnum } from '@enums/seller_role_enum'
import { useContextSelector } from 'use-context-selector'
import { formatDate, formatInitials } from '@utils/formatter'
import { PageListRowStandard } from '@components/page-list/styles'
import { OrganizationSellersContext } from '@pages/organization-sellers/context'
import {
  DotsThree,
  PencilSimple,
  TrashSimple,
  Lock,
} from '@phosphor-icons/react'
import { FormInputCheck } from '@andreciornavei/mui-boost'

type Props = {
  item: SellerEntity
}

export const OrganizationSellersTableRow = ({ item }: Props): JSX.Element => {
  const handleUpdateRecord = useContextSelector(
    OrganizationSellersContext,
    (s) => s.handleUpdateRecord
  )

  const handleRemoveRecord = useContextSelector(
    OrganizationSellersContext,
    (s) => s.handleRemoveRecord
  )

  return (
    <PageListRowStandard>
      <td align="center">
        <FormInputCheck name="check" />
      </td>
      <td align="left">
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            variant="rounded"
            sx={{ width: 32, height: 32, fontSize: 16 }}
            children={formatInitials(item?.user?.name || item.email)}
          />
          <Stack direction="column" py={2}>
            <Typography
              variant="caption"
              color="muted.main"
              fontWeight="bold"
              lineHeight={1}
            >
              {item?.user?.name || `<desconhecido>`}
            </Typography>
            <Typography variant="body2" color="muted.main" lineHeight={1}>
              {item.email}
            </Typography>
          </Stack>
        </Stack>
      </td>
      <td align="left">
        <Stack direction="row" spacing={0.5} alignItems="center">
          {item.role === 'BLOCKED' && (
            <Lock
              size={14}
              color={theme.palette.primary.dark}
              weight="duotone"
            />
          )}
          <Typography variant="body2" color="muted.main">
            {_.get(SellerRoleEnum, item.role)}
          </Typography>
        </Stack>
      </td>
      <td align="center">
        <Typography variant="body2" color="muted.main">
          {formatDate(item.created_at)}
        </Typography>
      </td>
      <td>
        <Dropdown
          options={[
            {
              label: 'Editar',
              icon: PencilSimple,
              action: () => handleUpdateRecord(item),
            },
            {
              label: 'Excluir',
              icon: TrashSimple,
              action: () => handleRemoveRecord(item.id),
            },
          ]}
        >
          <IconButton size="small">
            <DotsThree />
          </IconButton>
        </Dropdown>
      </td>
    </PageListRowStandard>
  )
}
