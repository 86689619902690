import { MemberEntity } from '@entities/MemberEntity'
import React from 'react'
import { useApp } from '../../../hooks/app'
import { MemberProfileType } from '../../../types/member_profile_type'
import { ProfileSwitcherItemsInterface } from '../components/profile-switcher-items-interface'

export const ProfileSwitcherAdmin = (): JSX.Element => {
  const { user } = useApp()

  const members: MemberProfileType[] = React.useMemo(() => {
    if (user?.admin)
      return [
        {
          kind: 'admin',
          key: `admin::${user.id}`,
          label: user.name || 'unknown',
          value: {} as unknown as MemberEntity,
        },
      ]
    return []
  }, [user])

  return <ProfileSwitcherItemsInterface profile="admin" members={members} />
}
