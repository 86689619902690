import styled from '@emotion/styled'

export const ItemRow = styled.tr`
  margin-top: 6px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  & .visible-actions {
    display: none;
  }
  :hover {
    & .visible-actions {
      display: flex;
    }
    & .hidden-actions {
      display: none;
    }
  }
`
