import { CardContainer } from '@components/card-container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { theme } from '@theme/index'
import { useContextSelector } from 'use-context-selector'
import { DatabaseDetailContext } from './context'
import {
  DatabaseStatusEnum,
  DatabaseStatusIconsLg,
} from '@enums/database_status_enum'
import _ from 'lodash'
import { Shimmer } from '@components/shimmer'
import { formatDateTime } from '@utils/formatter'
import { DatabasePlatformEnumIcons } from '@enums/database_platform_enum'
import { useForm } from 'react-hook-form'

export const DatabaseDetailView = (): JSX.Element => {
  const loading = useContextSelector(DatabaseDetailContext, (s) => s.loading)
  const progress = useContextSelector(DatabaseDetailContext, (s) => s.progress)
  const database = useContextSelector(DatabaseDetailContext, (s) => s.database)
  const totalDownloads = useContextSelector(
    DatabaseDetailContext,
    (s) => s.totalDownloads
  )
  const currentDownloads = useContextSelector(
    DatabaseDetailContext,
    (s) => s.currentDownloads
  )
  const handleNewRecord = useContextSelector(
    DatabaseDetailContext,
    (s) => s.handleNewRecord
  )

  const { handleSubmit } = useForm({})

  return (
    <CardContainer>
      <form onSubmit={handleSubmit((data) => {})}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3} alignItems="center">
              <Stack
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ height: '100%' }}
              >
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  color="primary.dark"
                >
                  Integridade
                </Typography>
                <Stack direction="column" alignItems="center" spacing={0.5}>
                  <Shimmer
                    width={40}
                    height={40}
                    variant="circular"
                    isLoading={loading}
                  >
                    {_.get(DatabaseStatusIconsLg, String(database?.status))}
                  </Shimmer>
                  {
                    <Shimmer isLoading={loading} width={65} height={16}>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="muted.main"
                      >
                        {_.get(
                          DatabaseStatusEnum,
                          String(database?.status),
                          'Loading...'
                        )}
                      </Typography>
                    </Shimmer>
                  }
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  disabled
                  style={{ fontWeight: 'bold' }}
                >
                  Causas
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={3} alignItems="center">
              <Stack
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ height: '100%' }}
              >
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  color="primary.dark"
                >
                  Downloads
                </Typography>
                <Stack direction="column" alignItems="center" spacing={0.5}>
                  <Shimmer
                    width={40}
                    height={40}
                    variant="circular"
                    isLoading={progress}
                  >
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderWidth: '4px',
                          borderStyle: 'solid',
                          borderRadius: 60,
                          borderColor: theme.palette.muted.light,
                        }}
                      >
                        <Typography
                          fontSize={11}
                          fontWeight="bold"
                          variant="caption"
                          color="muted.main"
                        >
                          {`${Math.round(
                            (currentDownloads / totalDownloads) * 100
                          )}%`}
                        </Typography>
                      </Box>
                      <CircularProgress
                        size={40}
                        variant="determinate"
                        value={Math.round(
                          (currentDownloads / totalDownloads) * 100
                        )}
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'light'
                              ? '#1a90ff'
                              : '#308fe8',
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                        }}
                      />
                    </Box>
                  </Shimmer>
                  <Shimmer isLoading={loading} width={65} height={16}>
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                      color="muted.main"
                    >
                      {currentDownloads} de {totalDownloads}
                    </Typography>
                  </Shimmer>
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  disabled
                  style={{ fontWeight: 'bold' }}
                >
                  Ver detalhes
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={3} alignItems="center">
              <Stack
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ height: '100%' }}
              >
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  color="primary.dark"
                >
                  Versão de Implantação
                </Typography>
                <Stack direction="column" alignItems="center" spacing={1}>
                  <Shimmer width={120} height={30} isLoading={progress}>
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      style={{ fontSize: 11, fontWeight: 'bold' }}
                    >
                      @{database?.id}
                    </Button>
                  </Shimmer>
                  <Shimmer isLoading={progress} width={65} height={16}>
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                      color="muted.main"
                    >
                      {formatDateTime(String(database?.created_at))}
                    </Typography>
                  </Shimmer>
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  style={{ fontWeight: 'bold' }}
                  onClick={handleNewRecord}
                  disabled={progress}
                >
                  Enviar nova base
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ height: '100%' }}
              >
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  color="primary.dark"
                >
                  Plataforma
                </Typography>
                <Stack direction="column" alignItems="center" spacing={0.5}>
                  <Shimmer
                    width={40}
                    height={40}
                    variant="circular"
                    isLoading={progress}
                  >
                    {_.get(
                      DatabasePlatformEnumIcons,
                      String(database?.platform)
                    )}
                  </Shimmer>
                  <Shimmer isLoading={progress} width={65} height={16}>
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                      color="muted.main"
                    >
                      Arquivo
                    </Typography>
                  </Shimmer>
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  disabled
                  style={{ fontWeight: 'bold' }}
                >
                  Alterar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </form>
    </CardContainer>
  )
}
