import { useAuth } from '@hooks/auth'
import { Stack, Button, Box } from '@mui/material'
import { SignOut, UserCircle } from '@phosphor-icons/react'
import { HeaderConnectionStatus } from '../ConnectionStatus'
import { ProfileSwitcher } from '@containers/profile-switcher'

export const HeaderMenuAuthenticated = (): JSX.Element => {
  const auth = useAuth()

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      <ProfileSwitcher />
      <Box />
      <Stack direction="row" spacing={2} alignItems="center">
        <HeaderConnectionStatus />
        <Button
          variant="text"
          color="secondary"
          endIcon={<UserCircle size={21} weight="duotone" />}
        >
          Minha conta
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={() => auth.disconnect()}
          endIcon={<SignOut size={18} weight="duotone" />}
        >
          Desconectar
        </Button>
      </Stack>
    </Stack>
  )
}
