import { Stack, Typography } from '@mui/material'
import { theme } from '@theme/index'
import { CheckCircle } from '@phosphor-icons/react'

export const ForgotPasswordFeedback = (): JSX.Element => {
  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <CheckCircle
        size={128}
        weight="duotone"
        color={theme.palette.success.main}
      />
      <Typography variant="h1" color="secondary.main">
        Verifique seu email
      </Typography>
      <Typography variant="subtitle1" color="muted.main" textAlign="center">
        Envimos um email com instruções para você cadastrar sua nova senha
      </Typography>
    </Stack>
  )
}
