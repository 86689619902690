import { Stack } from '@mui/material'
import { AdminOrganizationTabSelector } from './containers/tab-selector'
import { AdminOrganizationTabItems } from './containers/tab-items'
import { AdminOrganizationDetails } from './containers/details'

export const AdminOrganizationView = (): JSX.Element => {
  return (
    <Stack direction="column" spacing={3}>
      <AdminOrganizationDetails />
      <AdminOrganizationTabItems />
      <AdminOrganizationTabSelector />
    </Stack>
  )
}
