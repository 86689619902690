import _ from 'lodash'
import axios from 'axios'
import { ApiAction } from '@hooks/api/action'
import { PresignedUrlType } from '@type/presigned_url_type'
import { PaginationType } from '@type/pagination_type'
import { DatabaseEntity } from '@entities/DatabaseEntity'

export class DatabaseApi extends ApiAction {
  async presignUrl(organizationId: string): Promise<DatabaseEntity> {
    const response = await this.http.post<DatabaseEntity>(
      `/organizations/${organizationId}/database`
    )
    return response.data
  }

  async upload({ url, fields }: PresignedUrlType, file: File): Promise<void> {
    const client = await axios.create({ baseURL: url })
    const formData = new FormData()
    for (const fieldKey in fields) {
      formData.append(fieldKey, _.get(fields, fieldKey))
    }
    formData.append('file', file)
    await client.post('', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  async cancel(databaseId: string): Promise<DatabaseEntity> {
    const response = await this.http.put<DatabaseEntity>(
      `/databases/${databaseId}/cancel`
    )
    return response.data
  }

  async find(
    organizationId: string,
    page: number,
    limit: number
  ): Promise<PaginationType<DatabaseEntity>> {
    const response = await this.http.get<PaginationType<DatabaseEntity>>(
      `/organizations/${organizationId}/databases?page=${page}&limit=${limit}`
    )
    return response.data
  }
}
