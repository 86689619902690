import { UsersPageController } from './controller'
import { UsersPageProps } from './types'
import { UsersPageView } from './view'

export const UsersPage = (props: UsersPageProps): JSX.Element => {
  return (
    <UsersPageController {...props}>
      <UsersPageView />
    </UsersPageController>
  )
}
