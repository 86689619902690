import { Stack, Typography } from '@mui/material'
import { OrganizationSellers } from '@pages/organization-sellers'

export const SelfOrganizationSellers = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" color="secondary.main">
        Representantes
      </Typography>
      <OrganizationSellers />
    </Stack>
  )
}
