import { Alert, Stack, Typography } from '@mui/material'
import { Warning } from '@phosphor-icons/react'
import { theme } from '@theme/index'
import { HttpMessageType } from '@type/http_message_type'

type Props = {
  error: HttpMessageType
}

export const AcceptInviteError = (props: Props): JSX.Element => {
  return (
    <Alert
      variant="standard"
      color="error"
      icon={
        <Warning
          size={36}
          weight="duotone"
          color={theme.palette.error.main}
          style={{ marginRight: 16 }}
        />
      }
      sx={{ width: '100%', maxWidth: 640, py: 2, px: 5 }}
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="h6" lineHeight={0.5}>
          {props.error.title}
        </Typography>
        <Typography variant="body2" lineHeight={1.5}>
          {props.error.message}
        </Typography>
      </Stack>
    </Alert>
  )
}
