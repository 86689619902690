import _ from 'lodash'
import { ItemRow } from './styles'
import { OrganizationsContext } from './context'
import { DotsThree, Eye } from '@phosphor-icons/react'
import { useContextSelector } from 'use-context-selector'
import { PageListComponent } from '@components/page-list'
import { OrganizationEntity } from '@entities/OrganizationEntity'
import { OrgStatusColorEnum, OrgStatusEnum } from '@enums/org_status_enum'
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { formatInitials } from '@utils/formatter'
import { FormInputCheck } from '@andreciornavei/mui-boost'

export const OrganizationsView = () => {
  const handleNewRecord = useContextSelector(
    OrganizationsContext,
    (s) => s.handleNewRecord
  )

  const handlePagination = useContextSelector(
    OrganizationsContext,
    (s) => s.handlePagination
  )

  const handleOpenOrganization = useContextSelector(
    OrganizationsContext,
    (s) => s.handleOpenOrganization
  )

  return (
    <PageListComponent
      title="Organizações"
      emptyMessage="Vazio"
      handleNewRecord={handleNewRecord}
      handlePagination={handlePagination}
      renderHeader={() => (
        <tr>
          <th style={{ padding: '0px', width: 50 }} />
          <th style={{ padding: '0px 10px', width: 60 }} align="left">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              STATUS
            </Typography>
          </th>
          <th style={{ padding: '0px 10px' }} align="left">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              NOME / EMAIL
            </Typography>
          </th>
          <th style={{ padding: '0px 10px' }} align="left">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              DOCUMENTO
            </Typography>
          </th>
          <th style={{ padding: '0px 10px', width: 100 }} align="right">
            <Typography variant="caption" color="muted.main" fontWeight="bold">
              AÇÕES
            </Typography>
          </th>
        </tr>
      )}
      renderItem={(item: OrganizationEntity) => (
        <ItemRow key={`row-${item.id}`}>
          <td style={{ padding: 10, paddingRight: 0 }}>
            <FormInputCheck name="check" />
          </td>
          <td style={{ padding: 10 }}>
            <Chip
              color={_.get(OrgStatusColorEnum, item.status)}
              variant="outlined"
              label={_.get(OrgStatusEnum, item.status)}
              size="small"
            />
          </td>
          <td style={{ padding: 10 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                variant="rounded"
                sx={{ width: 32, height: 32, fontSize: 16 }}
                children={formatInitials(item.name || 'unknown')}
              />
              <Stack direction="column" spacing={0}>
                <Typography variant="body2" color="secondary.main">
                  {item.name || 'unknown'}
                </Typography>
                <Typography variant="caption" color="muted.main">
                  {item.email || 'unknown'}
                </Typography>
              </Stack>
            </Stack>
          </td>
          <td style={{ padding: 10 }}>
            <Typography variant="body2" color="secondary.main">
              {item.document}
            </Typography>
          </td>
          <td style={{ padding: 10 }} align="right">
            <IconButton size="small" className="hidden-actions">
              <DotsThree />
            </IconButton>
            <Button
              size="small"
              className="visible-actions"
              onClick={(_) => handleOpenOrganization(item.id)}
              variant="text"
              startIcon={<Eye weight="duotone" />}
              sx={{ fontSize: 11, px: 1 }}
            >
              Detalhes
            </Button>
          </td>
        </ItemRow>
      )}
    />
  )
}
