export enum InviteEnum {
  ACCEPTED = 'Aceito',
  PENDING = 'Pendente',
  REFUSED = 'Recusado',
}

export enum InviceColorEnum {
  ACCEPTED = 'success',
  PENDING = 'warning',
  REFUSED = 'error',
}

export type StatusEnumKeys = keyof typeof InviteEnum
