import { X, SignOut } from '@phosphor-icons/react'
import { SideMenuItem } from '../side-menu-item'
import { SideMenuPadding, SideMenuWrapper } from './styles'
import { Avatar, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useAuth } from '@hooks/auth'
import { theme } from '@theme/index'
import { useApp } from '@hooks/app'
import { SideMenuProfileAdmin } from '@containers/side-menu-profile/side-menu-profile-admin'
import { SideMenuOrganization } from '@containers/side-menu-profile/side-menu-profile-organization'
import { formatInitials } from '@utils/formatter'

export function SideMenu() {
  // const { closeDrawer } = useDrawer()
  const { session } = useAuth()
  const { selectedProfile } = useApp()

  return (
    <SideMenuWrapper>
      {/*<!-- visible on mobile -->*/}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        sx={{
          px: 3,
          height: '60px',
          backgroundColor: theme.palette.common.black,
          display: { md: 'none' },
          borderLeftWidth: 1,
          borderLeftStyle: 'solid',
          borderLeftColor: theme.palette.common.white,
        }}
      >
        <Typography variant="h6" color="common.white">
          Menu
        </Typography>
        <IconButton>
          <X color="white" />
        </IconButton>
      </Stack>

      <Stack direction="column">
        <SideMenuPadding sx={{ px: 3, pt: 3, pb: 2 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              variant="rounded"
              sx={{ width: 32, height: 32, fontSize: 16 }}
            >
              {formatInitials(session?.user?.name || 'unknown')}
            </Avatar>
            <Stack direction="column" spacing={0}>
              <Typography
                variant="body1"
                color="secondary"
                className="textwrap"
                lineHeight="16px"
              >
                {session?.user?.name || 'unknown'}
              </Typography>
              <Typography
                variant="body2"
                color="muted.main"
                className="textwrap"
                lineHeight="16px"
              >
                {session?.user?.email || 'unknown'}
              </Typography>
            </Stack>
          </Stack>
        </SideMenuPadding>
        <Divider textAlign="left" sx={{ my: 1, mx: 3 }} />
      </Stack>

      {selectedProfile?.kind === 'admin' && <SideMenuProfileAdmin />}
      {selectedProfile?.kind === 'organization' && <SideMenuOrganization />}

      {/*<!-- visible on mobile -->*/}
      <Stack direction="column" sx={{ display: { md: 'none' } }}>
        <Divider textAlign="left" sx={{ my: 1 }}>
          <Typography variant="caption" color="muted.main">
            minha conta
          </Typography>
        </Divider>
        <SideMenuPadding sx={{ p: { xs: '12px', md: '24px' } }}>
          <SideMenuItem
            href=""
            // onClick={() => auth.disconnect()}
            label="Sair"
            icon={SignOut}
          />
        </SideMenuPadding>
      </Stack>
    </SideMenuWrapper>
  )
}
