import { Button, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { OrganizationSettingsContext } from '@pages/organization-settings/context'
import { theme } from '@theme/index'
import { useContextSelector } from 'use-context-selector'

export const DeleteSection = (): JSX.Element => {
  const submiting = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.submiting
  )
  const handleConfirmDeleteOrganization = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.handleConfirmDeleteOrganization
  )
  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.error.main,
        backgroundColor: `${theme.palette.error.light}20`,
      }}
    >
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Typography
          fontWeight="bold"
          variant="subtitle1"
          color="secondary.main"
        >
          Excluir esta organização
        </Typography>
        <Typography variant="body2" color="secondary.main">
          Os usuários associados a essa organização perderão acesso a todas as
          informações.
          <br />
          <b>
            Você terá 15 dias para realizar o download dos seus dados (ou
            cancelar a exclusão dessa organização) antes que todas as
            informações sejam excluidas da nossa base de dados permanentemente.
          </b>
        </Typography>
        <Button
          color="error"
          variant="outlined"
          style={{ fontWeight: 'bold' }}
          disabled={submiting}
          onClick={handleConfirmDeleteOrganization}
        >
          excluir organização
        </Button>
      </Stack>
    </Box>
  )
}
