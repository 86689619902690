import React from 'react'
import { OrganizationSellerController } from './controller'
import { OrganizationSellerProps } from './types'
import { OrganizationSellerView } from './view'

export const OrganizationSeller = (
  props: OrganizationSellerProps
): JSX.Element => {
  return (
    <OrganizationSellerController {...props}>
      <OrganizationSellerView />
    </OrganizationSellerController>
  )
}
