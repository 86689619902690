export enum OrgStatusEnum {
  ACTIVE = 'Ativo',
  BLOCKED = 'Bloqueado',
}

export enum OrgStatusColorEnum {
  ACTIVE = 'success',
  BLOCKED = 'error',
}

export type OrgStatusEnumKeys = keyof typeof OrgStatusEnum
