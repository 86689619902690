import { UfEnum } from '@enums/uf_enum'
import { LoadingButton } from '@mui/lab'
import { Buildings } from '@phosphor-icons/react'
import { InputCep } from '../../components/input-cep'
import { useContextSelector } from 'use-context-selector'
import { OrganizationSettingsContext } from '../../context'
import { CardContainer } from '@components/card-container'
import { Stack, Box, Typography, Grid, Alert } from '@mui/material'
import { useForm } from 'react-hook-form'
import { OrganizationEntity } from '@entities/OrganizationEntity'
import {
  FormInputFormat,
  FormInputPhone,
  FormInputText,
} from '@andreciornavei/mui-boost'

export const UpdateSection = () => {
  const error = useContextSelector(OrganizationSettingsContext, (s) => s.error)
  const formRef = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.formRef
  )
  const success = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.success
  )
  const organization = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.organization
  )
  const submiting = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.submiting
  )
  const setSuccess = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.setSuccess
  )
  const handleUpdateOrganization = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.handleUpdateOrganization
  )

  const { handleSubmit, control } = useForm<Partial<OrganizationEntity>>({
    defaultValues: {
      id: organization?.id,
      name: organization?.name,
      document: organization?.document,
      contact: organization?.contact,
      address: organization?.address,
    },
  })

  return (
    <Stack direction="column" spacing={3}>
      {success && (
        <Alert color="success" onClose={() => setSuccess(false)}>
          Organização atualizada com sucesso
        </Alert>
      )}
      {error?.message && <Alert color="error">{error?.message}</Alert>}
      <form ref={formRef} onSubmit={handleSubmit(handleUpdateOrganization)}>
        <CardContainer title="Dados da organização" icon={Buildings}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInputText
                  name="name"
                  label="Nome da organização"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  error={error?.errors['name']}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputFormat
                  mask
                  disabled
                  name="document"
                  label="CNPJ"
                  theme="light"
                  control={control}
                  format="##.###.###/####-##"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color="primary.dark"
                  fontWeight="bold"
                >
                  Contato
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormInputText
                  name="contact.email"
                  type="email"
                  label="Email"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  error={error?.errors?.['contact.email']}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputPhone
                  theme="light"
                  disabled={submiting}
                  label="Telefone"
                  name="contact.phone"
                  control={control}
                  error={error?.errors?.['contact.phone']}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color="primary.dark"
                  fontWeight="bold"
                >
                  Endereço
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputCep
                  control={control}
                  disabled={submiting}
                  error={error?.errors?.['address.zipcode']}
                />
              </Grid>
              <Grid item xs={7}>
                <FormInputText
                  name="address.street"
                  label="Endereço"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  error={error?.errors?.['address.street']}
                />
              </Grid>
              <Grid item xs={2}>
                <FormInputFormat
                  type="tel"
                  mask
                  format="########"
                  name="address.number"
                  label="Número"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  error={error?.errors?.['address.number']}
                />
              </Grid>
              <Grid item xs={3}>
                <FormInputText
                  name="address.neighborhood"
                  label="Bairro"
                  theme="light"
                  control={control}
                  disabled={submiting}
                />
              </Grid>
              <Grid item xs={3}>
                <FormInputText
                  name="address.complementary"
                  label="Complemento"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  error={error?.errors?.['address.complementary']}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInputText
                  name="address.city"
                  label="Cidade"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  error={error?.errors?.['address.city']}
                />
              </Grid>
              <Grid item xs={2}>
                <FormInputText
                  name="address.state"
                  label="Estado"
                  theme="light"
                  control={control}
                  disabled={submiting}
                  options={UfEnum}
                  error={error?.errors?.['address.state']}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  loading={submiting}
                  variant="outlined"
                  sx={{ fontWeight: 'bold' }}
                >
                  Salvar alterações
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </CardContainer>
      </form>
    </Stack>
  )
}
