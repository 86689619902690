import React from 'react'
import { OrganizationSellersController } from './controller'
import { OrganizationSellersView } from './view'
import { OrganizationSellersProps } from './types'
export const OrganizationSellers = (
  props: OrganizationSellersProps
): JSX.Element => {
  return (
    <OrganizationSellersController {...props}>
      <OrganizationSellersView />
    </OrganizationSellersController>
  )
}
