import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { Panel } from './containers/panel'
import { Login } from './pages/login'
import { UsersPage } from '@pages/users'
import { Dashboard } from './pages/dashboard'
import { Companies } from './pages/companies'
import { WebsocketProvider } from './hooks/websocket'
import { PanelResume } from '@pages/profiles/organization/panel-resume'
import { PanelOrders } from '@pages/profiles/organization/panel-orders'
import { PanelVisits } from '@pages/profiles/organization/panel-visits'
import { SelfOrganizationDatabase } from '@pages/profiles/organization/database'
import { PanelSettings } from '@pages/profiles/organization/panel-settings'
import { PanelSubscription } from '@pages/profiles/organization/panel-subscription'
import { AdminResume } from '@pages/profiles/admin/resume'
import { AdminOrders } from '@pages/profiles/admin/orders'
import { Organizations } from '@pages/profiles/admin/organizations'
import { ForgotPassword } from '@pages/forgot-password'
import { ResetPassword } from '@pages/reset-password'
import { AdminOrganization } from '@pages/profiles/admin/organization'
import { AdminBilling } from '@pages/profiles/admin/billing'
import { AdminOrganizationMembers } from '@pages/profiles/organization/panel-members'
import { SelfOrganizationSellers } from '@pages/profiles/organization/sellers'
import { Signup } from '@pages/signup'
import { ActiveAccount } from '@pages/active-account'
import { AcceptInvite } from '@pages/accept-invite'

function PrivateRoutes() {
  const isAuthenticated = Boolean(localStorage.getItem(`@avantor/session`))
  return !!isAuthenticated ? (
    <WebsocketProvider>
      <Outlet />
    </WebsocketProvider>
  ) : (
    <Navigate to="/" />
  )
}

function PublicRoutes() {
  const isAuthenticated = Boolean(localStorage.getItem(`@avantor/session`))
  return !isAuthenticated ? <Outlet /> : <Navigate to="/orgs" />
}

function Router() {
  return (
    <Routes>
      <Route path="activation/:token" element={<ActiveAccount />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="sellers/accept/:token" element={<AcceptInvite />} />
      <Route element={<PublicRoutes />}>
        <Route path="" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route element={<Dashboard />}>
          <Route path="orgs">
            <Route path="" element={<Companies />} />
            <Route path="*" element={<Navigate to="/orgs" replace />} />
          </Route>
          <Route path="admin/dashboard" element={<Panel />}>
            <Route path="" element={<AdminResume />} />
            <Route path="subscriptions" element={<AdminOrders />} />
            <Route path="billing" element={<AdminBilling />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="organizations" element={<Organizations />} />
            <Route path="organizations/:id" element={<AdminOrganization />} />
            <Route
              path="*"
              element={<Navigate to="/admin/dashboard" replace />}
            />
          </Route>
          <Route path="org/:id" element={<Panel />}>
            <Route path="dashboard" element={<PanelResume />} />
            <Route path="orders" element={<PanelOrders />} />
            <Route path="visits" element={<PanelVisits />} />
            <Route path="database" element={<SelfOrganizationDatabase />} />
            <Route path="settings" element={<PanelSettings />} />
            <Route path="subscription" element={<PanelSubscription />} />
            <Route path="sellers" element={<SelfOrganizationSellers />} />
            <Route path="members" element={<AdminOrganizationMembers />} />
            <Route path="*" element={<Navigate to="/orgs" replace />} />
          </Route>
          <Route path="*" element={<div>404</div>} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Router
