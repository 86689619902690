/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react'
import { OrganizationCreationContext } from './context'
import {
  OrganizationCreationControllerProps,
  OrganizationCreationFormType,
} from './types'
import { useApi } from '@hooks/api'
import { OrganizationApi } from '@services/api/organization_api'
import { useSnackbar } from 'notistack'
import { useModal } from '@hooks/modal'

export const OrganizationCreationController = ({
  children,
}: OrganizationCreationControllerProps): JSX.Element => {
  const api = useApi()
  const { closeModal } = useModal()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmitForm = useCallback((data: OrganizationCreationFormType) => {
    setLoading(true)
    api
      .instanceOf<OrganizationApi>(OrganizationApi)
      .create(data)
      .then(() => {
        enqueueSnackbar('Organização criada com sucesso')
        closeModal()
      })
      .catch(() => enqueueSnackbar('Erro ao criar organização'))
      .finally(() => setLoading(false))
    console.log(data)
  }, [])

  const state = useMemo(
    () => ({ loading, handleSubmitForm }),
    [loading, handleSubmitForm]
  )
  return (
    <OrganizationCreationContext.Provider value={state}>
      {children}
    </OrganizationCreationContext.Provider>
  )
}
