import { UsersApi } from '@services/api/users_api'
import { UsersPageContext } from './context'
import { UsersPageControllerProps } from './types'
import { useApi } from '@hooks/api'

export const UsersPageController = (
  props: UsersPageControllerProps
): JSX.Element => {
  const api = useApi()

  const handlePagination = (page: number, limit: number) => {
    return api.instanceOf<UsersApi>(UsersApi).find(page, limit)
  }

  const state = {
    handlePagination,
  }

  return (
    <UsersPageContext.Provider value={state}>
      {props.children}
    </UsersPageContext.Provider>
  )
}
