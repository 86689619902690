import React from 'react'
import { Alert, Container, Stack, Typography } from '@mui/material'
import { Empty } from '../../components/empty'

export const CompaniesView = (): JSX.Element => {
  return (
    <Container sx={{ py: 3 }}>
      <Stack spacing={1}>
        <Typography variant="h5" color="secondary.main">
          Minhas empresas
        </Typography>
        <Alert severity="info">
          No avantor, você pode ter mais de uma empresa associada em sua conta
          com assinaturas individuais, podendo alterná-las a qualquer momento.
        </Alert>
        <Empty
          title="lista vazia"
          description="Nenhuma empresa cadastrada ainda..."
          action={{
            label: 'nova empresa',
            onClick: () => {},
          }}
        />
      </Stack>
    </Container>
  )
}
