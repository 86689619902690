import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from 'react'
import { SessionType } from '../../types/auth_type'
import { useStorage } from '../storage'
import { useNavigate } from 'react-router-dom'

const Context = createContext(
  {} as {
    session: SessionType | undefined
    authenticate: (session: SessionType) => void
    disconnect: () => void
    isAuthenticated: boolean
  }
)

const AuthProvider = (props: { children: JSX.Element }) => {
  const storage = useStorage()
  const navigate = useNavigate()
  // const navigate = useNavigate()
  const [session, setSession] = useState<SessionType | undefined>()

  const authenticated = () => {
    const session = storage.get('session')
    if (!!session) {
      setSession(JSON.parse(session) as SessionType)
    }
  }

  const authenticate = React.useCallback((session: SessionType) => {
    storage.set('session', JSON.stringify(session))
    authenticated()
    navigate('/', { replace: true })
    window.location.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disconnect = React.useCallback(() => {
    storage.remove('session')
    setSession(undefined)
    window.location.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(authenticated, [])

  const authProviderValues = useMemo(
    () => ({ session, authenticate, disconnect, isAuthenticated: !!session }),
    [authenticate, disconnect, session]
  )

  return (
    <Context.Provider value={authProviderValues}>
      {props.children}
    </Context.Provider>
  )
}

const useAuth = () => {
  const context = useContext(Context)
  return context
}

export { AuthProvider, useAuth }
