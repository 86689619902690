import { CircularProgress } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { Broadcast, WifiMedium, WifiSlash, WifiX } from '@phosphor-icons/react'

import { WebSocketStatus } from '@hooks/websocket/types'
import { WebsocketContext } from '@hooks/websocket/context'
import { theme } from '@theme/index'

export const HeaderConnectionStatus = (): JSX.Element => {
  const status = useContextSelector(WebsocketContext, (s) => s.status)

  if (status === WebSocketStatus.CONNECTING)
    return <CircularProgress size={16} />

  if (status === WebSocketStatus.OPEN)
    return (
      <WifiMedium
        size={21}
        weight="duotone"
        color={theme.palette.success.main}
      />
    )

  if (status === WebSocketStatus.CLOSED)
    return (
      <WifiSlash
        size={21}
        weight="duotone"
        color={theme.palette.warning.main}
      />
    )

  if (status === WebSocketStatus.ERROR)
    return <WifiX size={21} weight="duotone" color={theme.palette.error.main} />

  return (
    <Broadcast size={21} weight="duotone" color={theme.palette.info.main} />
  )
}
