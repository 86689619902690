import { useContextSelector } from 'use-context-selector'
import { ActiveAccountContext } from '../context'
import { ActiveAccountError } from './error'
import LoadingTag from '@components/loading-tag/LoadingTag'

export const ActiveAccountSelector = (): JSX.Element => {
  const loading = useContextSelector(ActiveAccountContext, (s) => s.loading)
  const error = useContextSelector(ActiveAccountContext, (s) => s.error)
  if (loading) return <LoadingTag message="Verificando token de ativação..." />
  if (error) return <ActiveAccountError error={error} />
  return <div>redirecionando...</div>
}
