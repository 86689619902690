import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { MembersApi } from '@services/api/member_api'
import { ModalFormComponent } from '@components/modal-form'
import { OrganizationMembersContext } from './context'
import { OrganizationMembersControllerProps } from './types'
import { AdminOrganizationMember } from '../organization-member'
import { ModalConfirmation } from '@components/modal-confirmation'
import { PageListRefType } from '@components/page-list'
import { MemberEntity } from '@entities/MemberEntity'

export const OrganizationMembersController = (
  props: OrganizationMembersControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const tableRef = React.useRef<PageListRefType | undefined>(undefined)
  const formRef = React.useRef<any>() as React.MutableRefObject<any>

  const handleLoadRecords = (
    page: number,
    limit: number,
    filters?: Record<string, string>
  ) => {
    return api
      .instanceOf<MembersApi>(MembersApi)
      .find(props.organizationId, page, limit, filters)
  }

  const handleRefresh = () => {
    tableRef.current?.refresh()
  }

  const handleNewRecord = () => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Convidar novo membro">
        <AdminOrganizationMember
          organizationId={props.organizationId}
          onSave={handleRefresh}
        />
      </ModalFormComponent>
    )
  }

  const handleUpdateRecord = (member: MemberEntity) => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Editar membro">
        <AdminOrganizationMember
          organizationId={props.organizationId}
          member={member}
          onSave={handleRefresh}
        />
      </ModalFormComponent>
    )
  }

  const handleRemoveMember = (memberId: string) => {
    modal.openModal(
      <ModalConfirmation
        title="Excluir membro"
        message="Ao excluir o membro, ele não terá mais acesso a nenhum recurso dessa organização"
        onConfirm={() =>
          api
            .instanceOf<MembersApi>(MembersApi)
            .delete(props.organizationId, memberId)
        }
        onSuccess={handleRefresh}
      />
    )
  }

  const state = {
    tableRef,
    formRef,
    handleLoadRecords,
    handleNewRecord,
    handleRemoveMember,
    handleUpdateRecord,
  }
  return (
    <OrganizationMembersContext.Provider value={state}>
      {props.children}
    </OrganizationMembersContext.Provider>
  )
}
