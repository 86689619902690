import React from 'react'
import { useParams } from 'react-router-dom'
import { ActiveAccountContext } from './context'
import { ActiveAccountControllerProps } from './types'
import { useApi } from '@hooks/api'
import { AuthApi } from '@services/api/auth_api'
import { useAuth } from '@hooks/auth'
import { HttpMessageType } from '@type/http_message_type'
import { useOnce } from '@hooks/once'

export const ActiveAccountController = (
  props: ActiveAccountControllerProps
): JSX.Element => {
  const api = useApi()
  const auth = useAuth()
  const { runOnce } = useOnce()
  const { token } = useParams<{ token: string }>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<HttpMessageType | undefined>(
    undefined
  )

  const handleSubmit = React.useCallback(() => {
    runOnce(() => {
      setError(undefined)
      setLoading(true)
      api
        .instanceOf<AuthApi>(AuthApi)
        .activeAccount(String(token))
        .then((response) => auth.authenticate(response))
        .catch((error) => setError(error.response.data))
        .finally(() => setLoading(false))
    })
  }, [runOnce, api, token, auth])

  React.useEffect(() => {
    handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const state = {
    error,
    loading,
  }
  return (
    <ActiveAccountContext.Provider value={state}>
      {props.children}
    </ActiveAccountContext.Provider>
  )
}
