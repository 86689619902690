import { useContextSelector } from 'use-context-selector'
import { OrganizationSettingsContext } from '../context'
import { FormInputFormat } from '@andreciornavei/mui-boost'
import { Control } from 'react-hook-form'
import { OrganizationEntity } from '@entities/OrganizationEntity'

type Props = {
  disabled: boolean
  error?: string
  control: Control<
    Partial<OrganizationEntity>,
    any,
    Partial<OrganizationEntity>
  >
}

export const InputCep = (props: Props) => {
  const handleFindCep = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.handleFindCep
  )

  const findingAddress = useContextSelector(
    OrganizationSettingsContext,
    (s) => s.findingAddress
  )

  return (
    <FormInputFormat
      type="tel"
      name="address.zipcode"
      label="CEP"
      theme="light"
      mask
      format="#####-###"
      control={props.control}
      loading={findingAddress}
      disabled={props.disabled || findingAddress}
      onChange={(e) => handleFindCep(e.target.value)}
      error={props?.error}
    />
  )
}
