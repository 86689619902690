import { AcceptInviteController } from './controller'
import { AcceptInviteView } from './view'

export const AcceptInvite = (): JSX.Element => {
  return (
    <AcceptInviteController>
      <AcceptInviteView />
    </AcceptInviteController>
  )
}
