import { OrganizationUploadController } from './controller'
import { OrganizationUploadProps } from './types'
import { OrganizationUploadView } from './view'

export const OrganizationUpload = (
  props: OrganizationUploadProps
): JSX.Element => {
  return (
    <OrganizationUploadController {...props}>
      <OrganizationUploadView />
    </OrganizationUploadController>
  )
}
