import { CircularProgress } from '@mui/material'
import { CheckCircle, Recycle } from '@phosphor-icons/react'
import { theme } from '@theme/index'
import { Prohibit, Warning } from '@phosphor-icons/react'

export enum DatabaseStatusEnum {
  FAILED = 'Falha',
  EXPIRED = 'Expirado',
  PENDING = 'Pendente',
  SUCCESS = 'Sucesso',
  CANCELLED = 'Cancelado',
  EXTRACTING = 'Processando',
}

export const DatabaseStatusIcons: Record<DatabaseStatusEnumKeys, JSX.Element> =
  {
    FAILED: (
      <Warning color={theme.palette.error.main} size={16} weight="duotone" />
    ),
    PENDING: <CircularProgress size={12} />,
    EXPIRED: (
      <Recycle color={theme.palette.info.main} size={16} weight="duotone" />
    ),
    SUCCESS: (
      <CheckCircle
        color={theme.palette.success.main}
        size={16}
        weight="duotone"
      />
    ),
    CANCELLED: (
      <Prohibit color={theme.palette.error.main} size={16} weight="duotone" />
    ),
    EXTRACTING: <CircularProgress size={12} />,
  }

export const DatabaseStatusIconsLg: Record<
  DatabaseStatusEnumKeys,
  JSX.Element
> = {
  FAILED: (
    <Warning color={theme.palette.error.main} size={40} weight="duotone" />
  ),
  PENDING: <CircularProgress size={40} />,
  EXPIRED: (
    <Recycle color={theme.palette.info.main} size={40} weight="duotone" />
  ),
  SUCCESS: (
    <img src={require('../assets/platforms/success.png')} alt="Success" />
  ),
  CANCELLED: (
    <Prohibit color={theme.palette.error.main} size={40} weight="duotone" />
  ),
  EXTRACTING: <CircularProgress size={40} />,
}

export type DatabaseStatusEnumKeys = keyof typeof DatabaseStatusEnum
