import { InviteSellerContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { InviteInterface } from '../../components/invite-interface'

export const InviteSellerView = (): JSX.Element => {
  const invite = useContextSelector(InviteSellerContext, (s) => s.invite)

  const handleAcceptInvite = useContextSelector(
    InviteSellerContext,
    (s) => s.handleAcceptInvite
  )

  const handleDeclineInvite = useContextSelector(
    InviteSellerContext,
    (s) => s.handleDeclineInvite
  )

  return (
    <InviteInterface
      title={invite.organization?.name || 'unknown'}
      message={<>Está convidando você para se tornar um vendedor</>}
      handleAcceptInvite={handleAcceptInvite}
      handleDeclineInvite={handleDeclineInvite}
    />
  )
}
