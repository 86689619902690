import Box from '@mui/material/Box'
import { WrapperComponent } from '@containers/wrapper'
import footerPattern from '@assets/images/bg-pattern.svg'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import { useContextSelector } from 'use-context-selector'
import { ForgotPasswordContext } from './context'
import { ForgotPasswordSelector } from './containers/selector'

export const ForgotPasswordView = (): JSX.Element => {
  const navToLogin = useContextSelector(
    ForgotPasswordContext,
    (s) => s.navToLogin
  )

  return (
    <WrapperComponent>
      <Box
        sx={{
          minHeight: 'calc(100vh - 180px)',
          backgroundImage: [`url(${footerPattern})`].join(','),
          backgroundPosition: ['bottom center'].join(','),
          backgroundRepeat: 'repeat-x',
          backgroundSize: '60px',
        }}
      >
        <Container sx={{ py: 12 }}>
          <Grid container spacing={2}>
            <Grid
              item
              sm={3}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            ></Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1} alignItems="start">
                  <Box width="100%">
                    <ForgotPasswordSelector />
                  </Box>
                </Stack>
                <Divider textAlign="center">
                  <Typography variant="body2">ou</Typography>
                </Divider>
                <Stack alignItems="center">
                  <Button variant="text" size="small" onClick={navToLogin}>
                    Retornar para o login
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </WrapperComponent>
  )
}
