// import { useApi } from '../../../../hooks/api'
// import { useApp } from '../../../../hooks/app'
import { InviteBoxContext } from '../../context'
import { InviteSellerContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { InviteSellerControllerProps } from './types'
import { MemberEntity } from '@entities/MemberEntity'
// import { V3InviteApi } from '../../../../profiles/seller/api/V3InviteApi'

export const InviteSellerController = (
  props: InviteSellerControllerProps
): JSX.Element => {
  // const api = useApi()
  // const { setUser } = useApp()
  const setAccepting = useContextSelector(
    InviteBoxContext,
    (s) => s.setAccepting
  )
  const setDeclining = useContextSelector(
    InviteBoxContext,
    (s) => s.setDeclining
  )

  const handleUpdateStatus = (
    invite: MemberEntity,
    status: 'accept' | 'decline'
  ) => {
    // api
    //   .instanceOf<V3InviteApi>(V3InviteApi)
    //   .handleInvite(invite.id, status)
    //   .then(setUser)
    //   .finally(() => {
    //     setRefusing(false)
    //     setAccepting(false)
    //   })
  }

  const handleAcceptInvite = () => {
    setDeclining(false)
    setAccepting(true)
    handleUpdateStatus(props.invite, 'accept')
  }

  const handleDeclineInvite = () => {
    setAccepting(false)
    setDeclining(true)
    handleUpdateStatus(props.invite, 'decline')
  }

  const state = {
    invite: props.invite,
    handleAcceptInvite,
    handleDeclineInvite,
  }
  return (
    <InviteSellerContext.Provider value={state}>
      {props.children}
    </InviteSellerContext.Provider>
  )
}
