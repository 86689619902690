import { PaginationType } from '@type/pagination_type'
import { SellerEntity } from '../../entities/SellerEntity'
import { ApiAction } from '../../hooks/api/action'
import { buildquery } from '@utils/buildquery'
import { OrganizationSellerFormType } from '@pages/organization-seller/types'

export class SellerApi extends ApiAction {
  async acceptInvite(token: string): Promise<boolean> {
    const response = await this.http.get<boolean>(`/sellers/accept/${token}`)
    return response.data
  }

  async find(
    organizationId: string,
    page: number,
    limit: number,
    filters?: Record<string, string>
  ): Promise<PaginationType<SellerEntity>> {
    const response = await this.http.get<PaginationType<SellerEntity>>(
      `/organizations/${organizationId}/sellers?page=${page}&limit=${limit}&${buildquery(
        filters
      )}`
    )
    return response.data
  }

  async create(
    organizationId: string,
    body: OrganizationSellerFormType
  ): Promise<SellerEntity> {
    const response = await this.http.post<SellerEntity>(
      `/organizations/${organizationId}/sellers`,
      body
    )
    return response.data
  }

  async update(
    organizationId: string,
    sellerId: string,
    body: OrganizationSellerFormType
  ): Promise<SellerEntity> {
    const response = await this.http.put<SellerEntity>(
      `/organizations/${organizationId}/sellers/${sellerId}`,
      body
    )
    return response.data
  }

  async delete(organizationId: string, id: string): Promise<SellerEntity> {
    const response = await this.http.delete<SellerEntity>(
      `/organizations/${organizationId}/sellers/${id}`
    )
    return response.data
  }
}
