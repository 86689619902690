import { useContextSelector } from 'use-context-selector'
import { AdminOrganizationContext } from '../context'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, Button, Stack, Typography } from '@mui/material'
import { ShimmerText } from '@components/shimmer-text'
import { Shimmer } from '@components/shimmer'
import { formatCNPJ } from '@utils/formatter'

export const AdminOrganizationDetails = (): JSX.Element => {
  const navigate = useNavigate()
  const loading = useContextSelector(AdminOrganizationContext, (s) => s.loading)
  const organization = useContextSelector(
    AdminOrganizationContext,
    (s) => s.organization
  )
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="start"
    >
      <Stack direction="column" spacing={1}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            variant="text"
            size="small"
            sx={{ py: 0.1 }}
            onClick={() => navigate('/admin/dashboard', { replace: true })}
          >
            Dashboard
          </Button>
          <Button
            variant="text"
            size="small"
            sx={{ py: 0.1 }}
            onClick={() =>
              navigate('/admin/dashboard/organizations', { replace: true })
            }
          >
            Organizações
          </Button>
          <ShimmerText isLoading={loading} height={16} words={3}>
            <Typography variant="caption" color="muted.main">
              {organization?.name || 'Indefinido'}
            </Typography>
          </ShimmerText>
        </Breadcrumbs>
        <Stack direction="row" alignItems="baseline" spacing={1}>
          <Shimmer isLoading={loading} height={32} width={220}>
            <Typography variant="h5" color="secondary.main">
              {organization?.name || 'Indefinido'}
            </Typography>
          </Shimmer>
          <Typography variant="caption" color="muted.main">
            /
          </Typography>
          <Stack direction="row" alignItems="baseline" spacing={0.5}>
            <Typography
              variant="caption"
              color="secondary.dark"
              fontWeight="bold"
            >
              CNPJ
            </Typography>
            <Shimmer isLoading={loading} height={16} width={80}>
              <Typography variant="body2" color="muted.main">
                {formatCNPJ(organization?.document || 'indefinido')}
              </Typography>
            </Shimmer>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
