import { Stack } from '@mui/material'
import { UsersThree } from '@phosphor-icons/react'
import { useContextSelector } from 'use-context-selector'
import { PageListComponent } from '@components/page-list'
import { CardContainer } from '@components/card-container'
import { OrganizationMembersContext } from './context'
import { OrganizationMembersFilters } from './containers/filters'
import { OrganizationMembersTableRow } from './containers/table-row'
import { OrganizationMembersTableHeader } from './containers/table-header'

export const OrganizationMembersView = (): JSX.Element => {
  const tableRef = useContextSelector(
    OrganizationMembersContext,
    (s) => s.tableRef
  )

  const handleLoadRecords = useContextSelector(
    OrganizationMembersContext,
    (s) => s.handleLoadRecords
  )

  const handleNewRecord = useContextSelector(
    OrganizationMembersContext,
    (s) => s.handleNewRecord
  )

  return (
    <Stack direction="column" spacing={2}>
      <OrganizationMembersFilters />
      <CardContainer>
        <PageListComponent
          ref={tableRef}
          title="Lista de membros"
          titleVariant="caption"
          titleColor="muted.main"
          icon={UsersThree}
          rowSpacing={5}
          emptyMessage="Vazio"
          handlePagination={handleLoadRecords}
          handleNewRecord={handleNewRecord}
          renderHeader={() => <OrganizationMembersTableHeader />}
          renderItem={(item) => (
            <OrganizationMembersTableRow key={`row-${item.id}`} item={item} />
          )}
        />
      </CardContainer>
    </Stack>
  )
}
