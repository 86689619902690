import _ from 'lodash'
import Box from '@mui/material/Box'
import { theme } from '@theme/index'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { OrganizationDatabaseContext } from '../context'
import { useContextSelector } from 'use-context-selector'
import { DatabaseEntity } from '@entities/DatabaseEntity'
import { PageListRowStandard } from '@components/page-list/styles'
import { MagnifyingGlass, PauseCircle } from '@phosphor-icons/react'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import {
  DatabaseStatusEnum,
  DatabaseStatusIcons,
} from '@enums/database_status_enum'
import { OrganizationDatabaseExpireLabel } from './expire-label'
import { FormInputCheck } from '@andreciornavei/mui-boost'

type Props = {
  item: DatabaseEntity
}

export const OrganizationDatabaseTableRow = ({ item }: Props): JSX.Element => {
  const handleCancelUpload = useContextSelector(
    OrganizationDatabaseContext,
    (s) => s.handleCancelUpload
  )

  return (
    <PageListRowStandard>
      <td align="center">
        <FormInputCheck name="check" />
      </td>
      <td align="left">
        <Stack direction="row" spacing={1} alignItems="center">
          {_.get(DatabaseStatusIcons, item.status)}
          <Typography variant="body2" color="muted.main">
            {_.get(DatabaseStatusEnum, item.status)}
          </Typography>
        </Stack>
      </td>
      <td
        align="left"
        width="100%"
        style={{ paddingLeft: 25, paddingRight: 25 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.muted.light,
            borderRadius: 32,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={Math.round(
                ((item.sellers || []).filter((i: any) => i.downloaded === true)
                  .length /
                  (item.sellers || []).length) *
                  100
              )}
              sx={{
                borderRadius: 5,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor:
                    theme.palette.grey[
                      theme.palette.mode === 'light' ? 200 : 800
                    ],
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor:
                    theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                },
              }}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="caption" fontWeight="bold" color="muted.main">
              {
                (item.sellers || []).filter((i: any) => i.downloaded === true)
                  .length
              }
              {` `}/ {(item.sellers || []).length}
            </Typography>
          </Box>
        </Box>
      </td>
      <td align="left">
        <OrganizationDatabaseExpireLabel expire_at={item.expire_at} />
      </td>
      <td align="right" style={{ paddingRight: 5 }}>
        {['PENDING', 'EXTRACTING'].includes(item.status) ? (
          <IconButton size="small" onClick={() => handleCancelUpload(item.id)}>
            <PauseCircle weight="duotone" color={theme.palette.warning.main} />
          </IconButton>
        ) : (
          <IconButton size="small">
            <MagnifyingGlass
              weight="duotone"
              color={theme.palette.primary.main}
            />
          </IconButton>
        )}
      </td>
    </PageListRowStandard>
  )
}
