import { OrganizationOverviewController } from './controller'
import { OrganizationOverviewProps } from './types'
import { OrganizationOverviewView } from './view'

export const OrganizationOverview = (
  props: OrganizationOverviewProps
): JSX.Element => {
  return (
    <OrganizationOverviewController {...props}>
      <OrganizationOverviewView />
    </OrganizationOverviewController>
  )
}
