import { OrganizationCreationView } from './view'
import { OrganizationCreationProps } from './types'
import { OrganizationCreationController } from './controller'

export const OrganizationCreation = (props: OrganizationCreationProps) => {
  return (
    <OrganizationCreationController {...props}>
      <OrganizationCreationView />
    </OrganizationCreationController>
  )
}
