import { styled, List, Box } from '@mui/material'

export const SideMenuWrapper = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.gray.light,
  minHeight: '100%',
  width: '100%',
  padding: 0,
}))

export const SideMenuPadding = styled(Box)({
  padding: 24,
})
