import { Container, styled } from '@mui/material'

const WrapperRoot = styled(Container)(({ theme }) => ({
  boxShadow: `0px 0px 128px ${theme.palette.gray.main}`,
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
}))

const WrapperContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 180px)',
  paddingBottom: '100px',
})

const WrapperFooter = styled('div')({
  marginTop: '-100px',
})

export const Wrapper = {
  Root: WrapperRoot,
  Content: WrapperContent,
  Footer: WrapperFooter,
}
