import { theme } from '@theme/index'
import { LoadingButton } from '@mui/lab'
import { OrganizationCreationContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { Box, DialogActions, Grid, Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { OrganizationCreationFormType } from './types'
import { FormInputFormat, FormInputText } from '@andreciornavei/mui-boost'

export const OrganizationCreationView = () => {
  const loading = useContextSelector(
    OrganizationCreationContext,
    (s) => s.loading
  )
  const handleSubmitForm = useContextSelector(
    OrganizationCreationContext,
    (s) => s.handleSubmitForm
  )

  const { handleSubmit, control } = useForm<OrganizationCreationFormType>()

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={3} px={3}>
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Typography variant="subtitle2" fontWeight="bold" color="secondary">
              Dados da empresa
            </Typography>
            <Typography variant="caption" color="muted.main">
              Os dados da empresa são utilizados para identificação, o{' '}
              <b>CNPJ</b> deve ser único no sistema e é utilizado como
              identificador para diversas operações de transmissão de dados.
            </Typography>
          </Stack>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormInputText
                  name="name"
                  label="Nome"
                  disabled={loading}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputFormat
                  mask
                  label="CNPJ"
                  name="document"
                  format="##.###.###/####-##"
                  disabled={loading}
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Typography variant="subtitle2" fontWeight="bold" color="secondary">
              Administrador principal
            </Typography>
            <Typography variant="caption" color="muted.main">
              Um email será enviado para que o administrador principal da
              empresa possa criar uma conta no avantor e começar a utilizar a
              plataforma.
            </Typography>
          </Stack>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormInputText
                  name="email"
                  label="E-mail"
                  control={control}
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Stack>
      <DialogActions
        sx={{
          py: 3,
          px: 3,
          mt: 3,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <LoadingButton
          variant="contained"
          size="medium"
          type="submit"
          loading={loading}
        >
          Concluir
        </LoadingButton>
      </DialogActions>
    </form>
  )
}
