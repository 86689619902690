import React from 'react'
import { WrapperComponent } from '../../containers/wrapper'
import { Outlet } from 'react-router-dom'
import { useApp } from '@hooks/app'
import { LinearProgress } from '@mui/material'
export const Dashboard = (): JSX.Element => {
  const app = useApp()

  if (app.loading) {
    return (
      <WrapperComponent>
        <LinearProgress />
      </WrapperComponent>
    )
  }

  return (
    <WrapperComponent>
      <Outlet />
    </WrapperComponent>
  )
}
