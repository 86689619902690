import { useModal } from '@hooks/modal'
import { OrganizationOverviewContext } from './context'
import { OrganizationOverviewControllerProps } from './types'
import { ModalFormComponent } from '@components/modal-form'
import { OrganizationUpload } from '@pages/organization-upload'
import { useNavigate, useParams } from 'react-router-dom'

export const OrganizationOverviewController = (
  props: OrganizationOverviewControllerProps
): JSX.Element => {
  const modal = useModal()
  const { id } = useParams<{ id: string }>()
  const nativate = useNavigate()

  const handleNewRecord = () => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Enviar base de dados">
        <OrganizationUpload
          organizationId={String(id)}
          onSuccess={() => nativate(`/org/${String(id)}/database`)}
        />
      </ModalFormComponent>
    )
  }

  const state = {
    handleNewRecord,
  }
  return (
    <OrganizationOverviewContext.Provider value={state}>
      {props.children}
    </OrganizationOverviewContext.Provider>
  )
}
