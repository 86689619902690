import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { OrganizationSellerContext } from './context'
import { SellerApi } from '@services/api/seller_api'
import {
  OrganizationSellerControllerProps,
  OrganizationSellerFormType,
} from './types'
import { ReviewType } from '@type/review_type'

export const OrganizationSellerController = (
  props: OrganizationSellerControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const [submiting, setSubmiting] = React.useState<boolean>(false)
  const [error, setError] = React.useState<ReviewType | undefined>(undefined)

  const handleSubmit = (data: OrganizationSellerFormType) => {
    setSubmiting(true)
    setError(undefined)

    const actions = {
      create: () =>
        api.instanceOf<SellerApi>(SellerApi).create(props.organizationId, data),
      update: () =>
        api
          .instanceOf<SellerApi>(SellerApi)
          .update(props.organizationId, String(props?.seller?.id), data),
    }

    actions[props.seller ? 'update' : 'create']()
      .then((response) => {
        modal.closeModal()
        props.onSave(response)
      })
      .catch((error) => setError(error.response.data))
      .finally(() => setSubmiting(false))
  }

  const state = {
    error,
    submiting,
    handleSubmit,
    seller: props.seller,
  }
  return (
    <OrganizationSellerContext.Provider value={state}>
      {props.children}
    </OrganizationSellerContext.Provider>
  )
}
