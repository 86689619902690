import { UserEntity } from '@entities/UserEntity'
import { ApiAction } from '../../hooks/api/action'
import { SessionType } from '../../types/auth_type'
import { CredentialsType } from '../../types/creentials_type'
import { ForgotPasswordFormType } from '@type/forgot_password_form_type'
import { ResetPasswordFormType } from '@type/reset_password_form_type'
import { SignupType } from '@type/signup_type'
import { HttpMessageType } from '@type/http_message_type'

export class AuthApi extends ApiAction {
  async signup(payload: Partial<SignupType>): Promise<HttpMessageType> {
    const response = await this.http.post<HttpMessageType>(
      `/registration`,
      payload
    )
    return response.data
  }
  async login(credentials: Partial<CredentialsType>): Promise<SessionType> {
    const response = await this.http.post<SessionType>(`/login`, credentials)
    return response.data
  }
  async forgotPassword(
    payload: Partial<ForgotPasswordFormType>
  ): Promise<boolean> {
    const response = await this.http.post<boolean>(`/forgot-password`, payload)
    return response.data
  }
  async resetPassword(
    payload: Partial<ResetPasswordFormType>
  ): Promise<SessionType> {
    const response = await this.http.post<SessionType>(
      `/reset-password`,
      payload
    )
    return response.data
  }
  async activeAccount(token: string): Promise<SessionType> {
    const response = await this.http.get<SessionType>(`/activation/${token}`)
    return response.data
  }
  async session(): Promise<UserEntity> {
    const response = await this.http.get<UserEntity>(`/session`)
    return response.data
  }
}
