import { InviteSellerController } from './controller'
import { InviteSellerProps } from './types'
import { InviteSellerView } from './view'

export const InviteSeller = (props: InviteSellerProps): JSX.Element => {
  return (
    <InviteSellerController {...props}>
      <InviteSellerView />
    </InviteSellerController>
  )
}
