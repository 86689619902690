import React from 'react'
import { useApi } from '@hooks/api'
import { useOnce } from '@hooks/once'
import { useParams } from 'react-router-dom'
import { AcceptInviteContext } from './context'
import { SellerApi } from '@services/api/seller_api'
import { AcceptInviteControllerProps } from './types'
import { HttpMessageType } from '@type/http_message_type'

export const AcceptInviteController = (
  props: AcceptInviteControllerProps
): JSX.Element => {
  const api = useApi()
  const { runOnce } = useOnce()
  const { token } = useParams<{ token: string }>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<HttpMessageType | undefined>(
    undefined
  )

  const handleSubmit = React.useCallback(() => {
    runOnce(() => {
      setError(undefined)
      setLoading(true)
      api
        .instanceOf<SellerApi>(SellerApi)
        .acceptInvite(String(token))
        .catch((error) => setError(error.response.data))
        .finally(() => setLoading(false))
    })
  }, [runOnce, api, token])

  React.useEffect(() => {
    handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const state = {
    error,
    loading,
  }

  return (
    <AcceptInviteContext.Provider value={state}>
      {props.children}
    </AcceptInviteContext.Provider>
  )
}
