import React from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Button, DialogActions, Stack, Typography } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import { theme } from '@theme/index'
import { useContextSelector } from 'use-context-selector'
import { OrganizationUploadContext } from './context'
import { Upload } from '@phosphor-icons/react'

export const OrganizationUploadView = (): JSX.Element => {
  const [typeError, setTypeError] = React.useState<boolean>(false)
  const file = useContextSelector(OrganizationUploadContext, (s) => s.file)
  const loading = useContextSelector(
    OrganizationUploadContext,
    (s) => s.loading
  )
  const setFile = useContextSelector(
    OrganizationUploadContext,
    (s) => s.setFile
  )
  const handlePresignUrl = useContextSelector(
    OrganizationUploadContext,
    (s) => s.handlePresignUrl
  )

  return (
    <Stack>
      <Stack px={3} spacing={1}>
        <Typography variant="caption" color="muted.main">
          Envie um arquivo <b>.ZIP</b> contendo arquivos <b>.TXT</b> com as
          informações de cada representante comercial. Os arquivos devem seguir
          o formato "<b>CPF-VENDEDOR</b>_<b>CNPJ-EMPRESA</b>.TXT"
        </Typography>
        <FileUploader
          name="file"
          types={['ZIP']}
          onTypeError={() => setTypeError(true)}
          handleChange={(file: File) => [setTypeError(false), setFile(file)]}
        >
          <Box
            p={4}
            borderRadius={1}
            style={{
              border: `1px dashed ${
                !!typeError ? theme.palette.error.main : theme.palette.divider
              }`,
              backgroundColor: theme.palette.primary.light,
            }}
          >
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Box
                width={64}
                height={64}
                borderRadius={64}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: `${theme.palette.primary.main}20` }}
              >
                <Upload size={24} color={theme.palette.primary.main} />
              </Box>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  {!!file && (
                    <Typography variant="caption" color="muted.main">
                      Arquivo: <b>{file?.name}</b>
                    </Typography>
                  )}
                  <Button variant="contained" size="medium">
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => setFile(e?.target?.files?.[0])}
                    />
                    <Typography variant="body2">
                      Clique aqui para escolher
                    </Typography>
                  </Button>
                  <Typography variant="caption" color="primary.main">
                    ou arraste e solte o arquivo aqui
                  </Typography>
                </Stack>
                {typeError && (
                  <Typography variant="caption" color="error">
                    O arquivo deve conter a extensão <b>.zip</b>
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Box>
        </FileUploader>
      </Stack>
      <DialogActions
        sx={{
          py: 3,
          px: 3,
          mt: 3,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <LoadingButton
          type="submit"
          size="medium"
          variant="contained"
          disabled={!file || typeError}
          loading={['PRESIGN', 'UPLOAD'].includes(loading)}
          onClick={handlePresignUrl}
        >
          Enviar
        </LoadingButton>
      </DialogActions>
    </Stack>
  )
}
