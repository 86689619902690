import { theme } from '@theme/index'
import { LoadingButton } from '@mui/lab'
import { Lock } from '@phosphor-icons/react'
import { OrganizationSellerContext } from './context'
import { SellerRoleEnum } from '@enums/seller_role_enum'
import { useContextSelector } from 'use-context-selector'
import { Alert, DialogActions, Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  FormInputCheckbox,
  FormInputFormat,
  FormInputText,
} from '@andreciornavei/mui-boost'
import { OrganizationSellerFormType } from './types'

export const OrganizationSellerView = (): JSX.Element => {
  const seller = useContextSelector(OrganizationSellerContext, (s) => s.seller)

  const handleSubmitForm = useContextSelector(
    OrganizationSellerContext,
    (s) => s.handleSubmit
  )

  const error = useContextSelector(OrganizationSellerContext, (s) => s.error)

  const submiting = useContextSelector(
    OrganizationSellerContext,
    (s) => s.submiting
  )

  const { handleSubmit, control } = useForm<OrganizationSellerFormType>({
    defaultValues: {
      email: seller?.email,
      document: seller?.document,
      role: seller?.role,
      name: seller?.name,
    },
  })

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={3} px={3}>
        {error?.message && <Alert color="error">{error?.message}</Alert>}
        <Stack direction="column" spacing={2}>
          <Stack direction="column">
            <Typography variant="subtitle2" fontWeight="bold" color="secondary">
              Email de representante
            </Typography>
            <Typography variant="caption" color="muted.main" lineHeight={1.2}>
              Um email será enviado para o representante comercial com o convite
              para participar da organização. Após o envio do email, o usuário
              pode escolher aceitar ou rejeitar o convite.
            </Typography>
          </Stack>
          <FormInputText
            name="email"
            label="E-mail"
            control={control}
            disabled={submiting || !!seller}
            error={error?.errors?.email}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Stack direction="column">
            <Typography variant="subtitle2" fontWeight="bold" color="secondary">
              Documento (CPF)
            </Typography>
            <Typography variant="caption" color="muted.main" lineHeight={1.2}>
              O <b>CPF</b> é utilizado como um <b>código global</b> para
              transmitir os dados do representante para as plataformas
              integradas, garantindo uma integração efeiciente entre sistemas
              distintos.
            </Typography>
          </Stack>
          <FormInputFormat
            name="document"
            label="CPF"
            mask
            format="###.###.###-##"
            control={control}
            disabled={submiting || !!seller}
            error={error?.errors?.document}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Stack direction="row" spacing={1} alignItems="baseline">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                color={error?.errors?.role ? 'error' : 'secondary'}
              >
                Perfil
              </Typography>
              {error?.errors?.role && (
                <Typography
                  variant="caption"
                  lineHeight={1.1}
                  color="error"
                  fontSize={10}
                >
                  {error?.errors?.role}
                </Typography>
              )}
            </Stack>
            <Typography variant="caption" color="muted.main" lineHeight={1.2}>
              Você pode conceder níveis de acesso diferentes aos representantes
              garantindo que os usuários executem apenas os papéis pelos quais
              foram designados dentro do sistema.
            </Typography>
          </Stack>
          <FormInputCheckbox
            name="role"
            mode="single"
            control={control}
            options={[
              {
                enabled: !submiting,
                value: 'BLOCKED',
                title: SellerRoleEnum.BLOCKED,
                icon: () => <Lock />,
                description: (
                  <Typography
                    variant="caption"
                    color="muted.main"
                    lineHeight={1.2}
                  >
                    O representante não poderderá realizar mais nenhuma operação
                    junto a organização
                  </Typography>
                ),
              },
              {
                enabled: !submiting,
                value: 'SELLER',
                title: SellerRoleEnum.SELLER,
                description: (
                  <Typography
                    variant="caption"
                    color="muted.main"
                    lineHeight={1.2}
                  >
                    O representante pode realizar <b>Pedidos</b>,{' '}
                    <b>Pre-Cadastro de clientes</b>, <b>Visitas</b> e
                    <b>Recebimentos de bases</b>
                  </Typography>
                ),
              },
              {
                enabled: !submiting,
                value: 'SUPERVISOR',
                subtitle: '(Herda permissões do perfil de Vendedor)',
                title: SellerRoleEnum.SUPERVISOR,
                description: (
                  <Typography
                    variant="caption"
                    color="muted.main"
                    lineHeight={1.2}
                  >
                    Supervisores podem receber uma base de dados com o resumo
                    estatístico de todos os vendedores de sua equipe para
                    supervisão.
                  </Typography>
                ),
              },
            ]}
          />
        </Stack>
      </Stack>
      <DialogActions
        sx={{
          py: 3,
          px: 3,
          mt: 3,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <LoadingButton
          loading={submiting}
          type="submit"
          variant="contained"
          size="medium"
        >
          {seller ? 'atualizar representante' : 'enviar convite'}
        </LoadingButton>
      </DialogActions>
    </form>
  )
}
