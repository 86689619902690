export enum DatabasePlatformEnum {
  ARCHIVE = 'br.com.avantor.archive',
}

export type DatabasePlatformEnumKeys = keyof typeof DatabasePlatformEnum

export const DatabasePlatformEnumIcons: Record<
  DatabasePlatformEnum,
  JSX.Element
> = {
  [DatabasePlatformEnum.ARCHIVE]: (
    <img src={require('../assets/platforms/zipfile.png')} alt="Archive" />
  ),
}
