import { useApi } from '@hooks/api'
import { useApp } from '@hooks/app'
import { AuthApi } from '@services/api/auth_api'
import React from 'react'
import { ProfileSwitcherContext } from './context'
import { ProfileSwitcherControllerProps } from './types'

export const ProfileSwitcherController = (
  props: ProfileSwitcherControllerProps
): JSX.Element => {
  const api = useApi()
  const app = useApp()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLoadUser = () => {
    app.setLoading(true)
    api
      .instanceOf<AuthApi>(AuthApi)
      .session()
      .then((user) => app.setUser(user))
      .finally(() => app.setLoading(false))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(handleLoadUser, [])

  const state = {
    open,
    anchorEl,
    handleClick,
    handleClose,
  }

  return (
    <ProfileSwitcherContext.Provider value={state}>
      {props.children}
    </ProfileSwitcherContext.Provider>
  )
}
