import styled from '@emotion/styled'

export const PageListRowStandard = styled.tr`
  background-color: #ffffff80;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 0px;
  &:hover {
    background-color: #ffffff;
  }
`
