import React, { useImperativeHandle } from 'react'
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
  TypographyPropsVariantOverrides,
} from '@mui/material'
import { theme } from '@theme/index'
import EmptyIcon from '../../assets/images/nodata.svg'
import { ArrowClockwise, IconProps, Plus } from '@phosphor-icons/react'
import { OverridableStringUnion } from '@mui/types'
import { PaginationType } from '@type/pagination_type'
import { Variant } from '@mui/material/styles/createTypography'
import produce from 'immer'
import _ from 'lodash'

type PageListProps = {
  title?: string
  titleVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >

  titleColor?: string
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
  section?: string
  subtitle?: string
  rowSpacing?: number
  headerPaddingHorizontal?: number
  limit?: number
  disableRefresh?: boolean
  labelNewRecord?: string
  handleNewRecord?: () => void
  renderHeader?: () => JSX.Element
  renderWrapper?: (props: {
    children: JSX.Element | JSX.Element[]
  }) => JSX.Element
  itemKey?: string
  renderItem: (item: any, prevItem: any) => JSX.Element | JSX.Element[]
  emptyMessage: string
  handlePagination: (
    page: number,
    limit: number,
    filters: Record<string, string> | undefined
  ) => Promise<PaginationType<unknown>>
}

export type PageListRefType = {
  refresh: (filters?: Record<string, string>) => void
  updateRecord: (key: string, record: unknown) => void
}

export const PageListComponent = React.forwardRef<
  PageListRefType,
  PageListProps
>(
  (
    { itemKey = 'id', limit = 6, handlePagination, ...props }: PageListProps,
    ref
  ): JSX.Element => {
    const [page, setPage] = React.useState<number>(1)
    const [filters, setFilters] = React.useState<
      Record<string, string> | undefined
    >(undefined)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [pagination, setPagination] = React.useState<
      PaginationType<unknown> | undefined
    >(undefined)

    // expose refresh function
    // to outer function
    useImperativeHandle(ref, () => ({
      refresh: (filters?: Record<string, string>) => handleRefresh(filters),
      updateRecord: (key: string, record: unknown) =>
        handleUpdateRecord(key, record),
    }))

    // determine pages based on fetch pagination
    const pages = React.useMemo(() => {
      return pagination?.pagination?.pages || 0
    }, [pagination])

    // determine display records fetch pagination
    const displayRecords = React.useMemo(() => {
      return pagination?.results || []
    }, [pagination])

    // determine a function to override
    // an specific record by key
    const handleUpdateRecord = (key: string, record: unknown) => {
      setPagination(
        produce(pagination, (draft) => {
          if (draft) {
            draft.results = (draft?.results || []).map((result) => {
              if (_.get(result, itemKey) === key) return record
              else return result
            })
          }
        })
      )
    }

    // determine a function to
    // refresh search results
    const handleRefresh = (filters?: Record<string, string>) => {
      setIsLoading(true)
      setFilters(filters)
      handlePagination(1, limit, filters)
        .then(setPagination)
        .finally(() => setIsLoading(false))
    }

    // initialize first fetch if enabled
    React.useEffect(() => {
      setIsLoading(true)
      handlePagination(page, limit, filters)
        .then(setPagination)
        .finally(() => setIsLoading(false))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filters])

    return (
      <Stack direction="column" sx={{ py: 0, pl: 0, pr: 0 }} spacing={3}>
        {(props.handleNewRecord || props.title || !props.disableRefresh) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            px={props.headerPaddingHorizontal || 0}
          >
            <Stack direction="column" spacing={1}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 0, sm: 1 }}
                alignItems="baseline"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {props.icon && (
                    <props.icon color={theme.palette.muted.main} />
                  )}
                  {props.title && (
                    <Typography
                      lineHeight={0}
                      variant={props.titleVariant || 'h5'}
                      color={props.titleColor || 'secondary.main'}
                    >
                      {props.title}
                    </Typography>
                  )}
                </Stack>

                {props.section && (
                  <Typography
                    variant="h4"
                    color="muted.main"
                    sx={{ fontSize: { xs: 16, md: 24 } }}
                  >
                    / {props.section}
                  </Typography>
                )}
              </Stack>
              {props.subtitle && (
                <Typography
                  variant="caption"
                  color="muted.main"
                  lineHeight={1}
                  sx={{ fontSize: { xs: 14, md: 16 } }}
                >
                  {props.subtitle}
                </Typography>
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {!props.disableRefresh && (
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  disabled={isLoading}
                  onClick={() => handleRefresh(filters)}
                >
                  Atualizar
                </Button>
              )}
              {props.handleNewRecord && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={props.handleNewRecord}
                >
                  {props.labelNewRecord || 'Adicionar Novo'}
                </Button>
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <IconButton
                size="medium"
                color="secondary"
                sx={{ backgroundColor: 'muted.light' }}
                onClick={() => handleRefresh(filters)}
              >
                <ArrowClockwise />
              </IconButton>
              {props.handleNewRecord && (
                <IconButton
                  size="medium"
                  sx={{ backgroundColor: theme.palette.primary.main }}
                  onClick={props.handleNewRecord}
                >
                  <Plus />
                </IconButton>
              )}
            </Stack>
          </Stack>
        )}
        <Stack direction="column" spacing={2}>
          {!!isLoading && <LinearProgress color="primary" />}
          {!isLoading && displayRecords.length === 0 && (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={3}
              sx={{ py: 10 }}
            >
              <Box sx={{ maxWidth: 180, maxHeight: 180 }}>
                <img src={EmptyIcon} alt="empty" />
              </Box>
              <Typography variant="body1" color="muted.main">
                {props.emptyMessage}
              </Typography>
            </Stack>
          )}
          {displayRecords.length > 0 && (
            <table
              border={0}
              cellPadding={0}
              cellSpacing={0}
              style={{
                borderCollapse: 'separate',
                borderSpacing: `0 ${props.rowSpacing || '10'}px`,
              }}
            >
              {props.renderHeader && (
                <thead>
                  <props.renderHeader />
                </thead>
              )}
              <tbody>
                {displayRecords.map((record, index) =>
                  props.renderItem(record, displayRecords[index - 1])
                )}
              </tbody>
            </table>
          )}
        </Stack>
        {displayRecords.length > 0 && (
          <Stack direction="row" justifyContent="flex-end" px={3}>
            <Pagination
              count={pages}
              size="small"
              page={page}
              onChange={(_, page) => setPage(page)}
            />
          </Stack>
        )}
      </Stack>
    )
  }
)
