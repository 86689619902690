import { SideMenu } from '../side-menu'
import { Outlet } from 'react-router-dom'
import { PanelContainer, PanelSide, PanelSidePadding } from './styles'
import { InviteBox } from '@containers/invite-box'

export const Panel = (): JSX.Element => {
  return (
    <PanelContainer container>
      <PanelSide
        item
        xs={0}
        md={3}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <SideMenu />
      </PanelSide>
      <PanelSide item xs={12} md={9}>
        <InviteBox />
        <PanelSidePadding p={3}>
          <Outlet />
        </PanelSidePadding>
      </PanelSide>
    </PanelContainer>
  )
}
