import { DatabaseDetailController } from './controller'
import { DatabaseDetailProps } from './types'
import { DatabaseDetailView } from './view'

export const DatabaseDetail = (props: DatabaseDetailProps): JSX.Element => {
  return (
    <DatabaseDetailController {...props}>
      <DatabaseDetailView />
    </DatabaseDetailController>
  )
}
