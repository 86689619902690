import { ChartBar } from '@components/chart-bar'
import { formatCurrency } from '@utils/formatter'
import { OrganizationOverviewContext } from './context'
import { PageListComponent } from '@components/page-list'
import { useContextSelector } from 'use-context-selector'
import { CardContainer } from '@components/card-container'
import { FormInputCheck } from '@andreciornavei/mui-boost'
import { PageListRowStandard } from '@components/page-list/styles'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { CardContentCounter } from '@components/card-content-counter'
import { Database, Receipt, SuitcaseSimple } from '@phosphor-icons/react'

export const OrganizationOverviewView = (): JSX.Element => {
  const handleNewRecord = useContextSelector(
    OrganizationOverviewContext,
    (s) => s.handleNewRecord
  )

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CardContainer>
                <CardContentCounter
                  icon={Receipt}
                  title="Vendas"
                  total="17"
                  summary={{
                    Pedido: 5,
                    Troca: 2,
                    Bonificação: 10,
                  }}
                />
              </CardContainer>
            </Grid>
            <Grid item xs={6}>
              <CardContainer>
                <CardContentCounter
                  icon={SuitcaseSimple}
                  title="Vendedores"
                  total="26"
                  summary={{
                    Ativos: 10,
                    Inativos: 15,
                    Pendentes: 1,
                  }}
                />
              </CardContainer>
            </Grid>
            <Grid item xs={12}>
              <ChartBar
                title="Faturamento"
                history_kind="D"
                history_period={{
                  '2023-01-01': 100,
                  '2023-01-02': 200,
                  '2023-01-03': 170,
                  '2023-01-04': 120,
                  '2023-01-05': 320,
                  '2023-01-06': 270,
                  '2023-01-07': 240,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardContainer>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <CardContentCounter
                      icon={Database}
                      title="Base de dados"
                      total="26"
                      summary={{
                        Enviados: 10,
                        Recebidos: 15,
                        Pendentes: 1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="column">
                        <Typography variant="caption" color="muted.main">
                          Upload
                        </Typography>
                        <Typography variant="caption" color="primary.dark">
                          Transmitir base atualizada
                        </Typography>
                      </Stack>
                      <Button variant="contained" onClick={handleNewRecord}>
                        Enviar atualização
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CardContainer>
            <PageListComponent
              title="Últimos pedidos"
              titleVariant="caption"
              titleColor="muted.main"
              icon={SuitcaseSimple}
              disableRefresh
              rowSpacing={5}
              emptyMessage="Vazio"
              handlePagination={(page, limit) =>
                new Promise((resolve) =>
                  resolve({
                    pagination: {
                      limit: 10,
                      page: 1,
                      pages: 1,
                      total: 1,
                    },
                    results: [
                      { id: '001', amount: 3200 },
                      { id: '002', amount: 3200 },
                      { id: '003', amount: 3200 },
                      { id: '004', amount: 3200 },
                      { id: '005', amount: 3200 },
                    ],
                  })
                )
              }
              renderHeader={() => (
                <tr>
                  <th>&nbsp;</th>
                  <th align="left">
                    <Typography
                      variant="caption"
                      color="muted.main"
                      fontWeight="bold"
                    >
                      Nº do pedido
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      variant="caption"
                      color="muted.main"
                      fontWeight="bold"
                    >
                      Vendedor
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      variant="caption"
                      color="muted.main"
                      fontWeight="bold"
                    >
                      Cliente
                    </Typography>
                  </th>

                  <th align="left">
                    <Typography
                      variant="caption"
                      color="muted.main"
                      fontWeight="bold"
                    >
                      Data
                    </Typography>
                  </th>
                  <th align="right" style={{ paddingRight: 12 }}>
                    <Typography
                      variant="caption"
                      color="muted.main"
                      fontWeight="bold"
                    >
                      Valor
                    </Typography>
                  </th>
                </tr>
              )}
              renderItem={(item) => (
                <PageListRowStandard key={`row-${item.id}`}>
                  <td>
                    <FormInputCheck name="check" />
                  </td>
                  <td>
                    <Typography variant="body2" color="muted.main">
                      1000000
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" color="muted.main">
                      JOAO KLEBER
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" color="muted.main">
                      JOHN DOE LTDA
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body2" color="muted.main">
                      11/04/2023
                    </Typography>
                  </td>
                  <td align="right" style={{ paddingRight: 12 }}>
                    <Typography variant="body2" color="muted.main">
                      {formatCurrency(item.amount)}
                    </Typography>
                  </td>
                </PageListRowStandard>
              )}
            />
          </CardContainer>
        </Grid>
      </Grid>
    </Box>
  )
}
