import React from 'react'
import { useApi } from '@hooks/api'
import { useModal } from '@hooks/modal'
import { useParams } from 'react-router-dom'
import { OrganizationSellersContext } from './context'
import { SellerApi } from '@services/api/seller_api'
import { SellerEntity } from '@entities/SellerEntity'
import { PageListRefType } from '@components/page-list'
import { ModalFormComponent } from '@components/modal-form'
import { OrganizationSellersControllerProps } from './types'
import { OrganizationSeller } from '@pages/organization-seller'
import { ModalConfirmation } from '@components/modal-confirmation'

export const OrganizationSellersController = (
  props: OrganizationSellersControllerProps
): JSX.Element => {
  const api = useApi()
  const modal = useModal()
  const tableRef = React.useRef<PageListRefType | undefined>(undefined)
  const formRef = React.useRef<any>() as React.MutableRefObject<any>
  const { id: organizationId } = useParams<{ id: string }>()

  const handleLoadRecords = (
    page: number,
    limit: number,
    filters?: Record<string, string>
  ) => {
    return api
      .instanceOf<SellerApi>(SellerApi)
      .find(String(organizationId), page, limit, filters)
  }

  const handleRefresh = () => {
    tableRef.current?.refresh()
  }

  const handleNewRecord = () => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Adicionar novo representante">
        <OrganizationSeller
          organizationId={String(organizationId)}
          onSave={handleRefresh}
        />
      </ModalFormComponent>
    )
  }

  const handleUpdateRecord = (seller: SellerEntity) => {
    modal.openModal(
      <ModalFormComponent dialogTitle="Editar representante">
        <OrganizationSeller
          organizationId={String(organizationId)}
          seller={seller}
          onSave={handleRefresh}
        />
      </ModalFormComponent>
    )
  }

  const handleRemoveRecord = (memberId: string) => {
    modal.openModal(
      <ModalConfirmation
        title="Excluir representante"
        message="Ao excluir o representante, ele não terá mais acesso a nenhum recurso dessa organização"
        onConfirm={() =>
          api
            .instanceOf<SellerApi>(SellerApi)
            .delete(String(organizationId), memberId)
        }
        onSuccess={handleRefresh}
      />
    )
  }

  const state = {
    tableRef,
    formRef,
    handleLoadRecords,
    handleNewRecord,
    handleRemoveRecord,
    handleUpdateRecord,
  }
  return (
    <OrganizationSellersContext.Provider value={state}>
      {props.children}
    </OrganizationSellersContext.Provider>
  )
}
