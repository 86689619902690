import { Stack } from '@mui/material'
import { UpdateSection } from './containers/update-section'
import { DeleteSection } from './containers/delete-section'

export const OrganizationSettingsView = (): JSX.Element => {
  return (
    <Stack direction="column" spacing={3}>
      <UpdateSection />
      <DeleteSection />
    </Stack>
  )
}
